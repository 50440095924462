
import {Vue, Component, Prop} from 'vue-property-decorator'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

@Component({components: {VueTimepicker}})
export default class BaseTimePicker extends Vue {
  @Prop({required: true}) value : any
  @Prop() minuteInterval : any
  @Prop() label !: string
  @Prop() prependIcon !: string
  @Prop({default: "h:mm A"}) format !: string
  @Prop({default: "hour"}) hourLabel !: string
  @Prop({default: "minute"}) minuteLabel !: string
  @Prop({default: "second"}) secondLabel !: string
  @Prop({default: "AM/PM"}) apmLabel !: string
  @Prop({default: true}) manualInput !: boolean
  @Prop({default: true}) hideDropdown !: boolean
  @Prop() rules !: Function | boolean | string
}
