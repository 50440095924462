
import {Component, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import HealthReportForm from "./HealthReportForm.vue"
import {ResourceFormDialogMixin, ResourceFormSystemMediaUploadMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, PropertyResource, PropertyMaintenanceSystemsResource, MaintenanceJobResource, HealthReportResource} from "@/resources"
import { BaseHealthReportComponent } from '../base/BaseMixins'
import AsyncComputed from 'vue-async-computed-decorator'

@Component({components: {BaseResourceFormDialog, HealthReportForm}})
// NOTE ORDER MATTERS FOR MIXINS
export default class HealthReportFormDialog extends Mixins(ResourceFormDialogMixin, BaseHealthReportComponent, ResourceFormSystemMediaUploadMixin) { 

  @Prop({required : true}) readonly propertyResource!: PropertyResource
  @Prop({required : true}) readonly propertyMaintenanceSystem!: PropertyMaintenanceSystemsResource
  @Prop() readonly maintenanceJobResource !: MaintenanceJobResource
  
  @AsyncComputed()
  async showCascade() {
    // TODO must be an easier way, simple question, does a PMS have kids
    // shouldn't have to load all property pms and look for parent = us
    var allPms = await this.propertyResource.maintenanceSystems.getAssociation({projection: 'propertyMaintenanceSystemDetail'})
    var usId = this.propertyMaintenanceSystem.data().id

    return (!!allPms.find(pms => pms.data().parentSystemId == usId))
  }

  newModelFunction() : any {
        return this.rdata
            ? this.rdata
            : {...SettingsResource.defaultObject("healthReport"), 
          pmsId : this.propertyMaintenanceSystem.data().id,
          propertyMaintenanceSystem: this.propertyMaintenanceSystem.uriFull,
          job : this.maintenanceJobResource ? this.maintenanceJobResource.uriFull : undefined,
          property: this.propertyResource.uriFull }
  }

  newSystemMediaResource(id : any) {
    return new HealthReportResource(id)
  }

}
