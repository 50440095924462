import { render, staticRenderFns } from "./ClientAddDialog.vue?vue&type=template&id=64c16e7a"
import script from "./ClientAddDialog.vue?vue&type=script&lang=ts"
export * from "./ClientAddDialog.vue?vue&type=script&lang=ts"
import style0 from "./ClientAddDialog.vue?vue&type=style&index=0&id=64c16e7a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports