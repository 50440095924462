
import { EntityResource } from "@/resources"
import { Component, Prop } from "vue-property-decorator"
import JobLogNavigatorPanel from "./JobLogNavigatorPanel.vue"
import JobLogFormDialog from "./JobLogFormDialog.vue"
import BaseDeleteAssociationResourceItemDialog from "@/components/base/BaseDeleteAssociationResourceItemDialog.vue"
import BaseEventTimelineNavigatorDialog from "@/components/base/BaseEventTimelineNavigatorDialog.vue"
import { BaseMaintenanceJobLogComponent } from "../base/BaseMixins"

@Component({components:{JobLogNavigatorPanel, JobLogFormDialog, BaseEventTimelineNavigatorDialog, BaseDeleteAssociationResourceItemDialog}})
export default class JobLogNavigatorDialog extends BaseMaintenanceJobLogComponent {
    @Prop({ required : true, default: false}) readonly value!: boolean
    @Prop() readonly startingResource!: EntityResource
    @Prop({ default: false }) readonly hideOptions!: boolean

    editDialog : boolean = false
    deleteDialog : boolean = false

}
