import { SettingsResource } from '@/resources'

/**
 * Utility class for atomically adding multiple equipment/parts in one request, possibly creating new property
 * maintenance systems in the process.
 */
export default class propertyItemAdd {

    /**
     * Mandatory : the property we are adding to.
     */
    property : string | null = null
    
    /**
     * Mandatory: the type of item we are adding
     */
    prototype : string | null = null

    /**
     * Optional: If adding to exisitng equipment, the equipment we are adding to, items will get the same
     * PMS as the equipment.  No PMS or MS+PMS_P_G required.
     */
    parentEquipment : string | null = null

    /**
     * If adding in a situation where the system is unambiguous, we will have the target PMS
     */
    propertyMaintenanceSystem : string | null = null

    /**
     * If parent equipment, or a specific PMS is not provided, Used when adding a new PMS and the subtree is
     * ambiguous (in conjuction with the parentOrGrandparentPropertyMaintenanceSystem)
     */
    maintenanceSystem : string | null = null

    /**
     * Else there are more than one possible system of the same type it could belong to.  We need
     * the MS and an existing parent or grandparent PMS of the subtree we are adding to.
     */
    parentOrGrandparentPropertyMaintenanceSystem : string | null = null 
}