
import { Component, Prop, Vue} from "vue-property-decorator"

@Component({components : {}})
export default class BaseSchedulingEntry extends Vue {
  @Prop() readonly category !: string
  @Prop() readonly title !: string
  @Prop() readonly lineOne !: string
  @Prop() readonly lineTwo !: string
  @Prop() readonly lineThree !: string
  @Prop() readonly footer !: string
  @Prop({default: "#cccccc"}) readonly backgroundColor !: string
  @Prop() readonly foregroundColor !: string
  @Prop({default: false}) readonly loading !: boolean
  @Prop({default: true}) readonly outlined !: boolean
  @Prop({default: false}) readonly showContentWhenLoading !: boolean
  @Prop({default: false}) readonly singleLine !: boolean
  @Prop({default: 95}) readonly minHeight !: string | number
  @Prop() readonly maxHeight !: string | number
  @Prop({default: 178}) readonly width !: string | number
  @Prop() troubleColor !: string
  @Prop({default: 1}) alphaLevel !: number
  

  get alphaBackgroundColor() {
    const rgb = this.hexToRgb(this.backgroundColor);
    return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ", " + this.alphaLevel + ")"
  }

  get lineStyleAttr() {
    var sattrs : any = {
      'position' : 'relative',
      'width' : '100%'
    }

    if (this.troubleColor) {
      sattrs["border-left"] = "4px solid " + this.troubleColor
    }
    
    var keys = Object.keys(sattrs);
    if (keys.length == 0) return ""

    return Object.keys(sattrs).map(k => k + ": " + sattrs[k]).join(";") + ";"
  }

  get bodyStyleAttr() {
    var sattrs : any = {}
    if (this.troubleColor) {
      sattrs["border-left"] = "4px solid " + this.troubleColor
    }
    if (this.foregroundColor) {
      sattrs["color"] = this.foregroundColor
    }
    
    var keys = Object.keys(sattrs);
    if (keys.length == 0) return ""

    return Object.keys(sattrs).map(k => k + ": " + sattrs[k]).join(";") + ";"
  }

  hexToRgb(hex : string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : {r: 204, g: 204, b: 204};
  }
}
