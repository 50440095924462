
import { EntityResource } from '@/resources';
import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({components: {}})
export default class BaseReportButton extends Vue {
  @Prop({default: 'mdi-printer'}) readonly icon!: string
  @Prop({ default: () => (new URLSearchParams) }) params!: URLSearchParams
  @Prop({required: true}) readonly url !: string

  requesting : boolean = false

  clicked() {
    //@ts-ignore
    var newUrl = new URL(process.env.VUE_APP_API_BASE_URL + this.url);
    this.params.forEach((value, key) => {
      newUrl.searchParams.set(key, value)
    });
    
    var download = new EntityResource(newUrl.toString())
    this.requesting = true
    download.postNoFollow({}).then((s) => {
      
      //@ts-ignore
      var base = process.env.VUE_APP_API_BASE_URL;

      // download URL will be relative from base path, ensure
      // we use the correct base URL without doubling up on the /api prefix
      // TODO hacky
      //@ts-ignore
      var downloadURL = base?.replace(/\/api$/, s.headers.get("Location"));
      if (downloadURL) {
        window.location.href = downloadURL
      }

    }).finally(() => this.requesting = false)
   
  }
}
