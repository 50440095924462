
import { Component, Prop, Vue} from 'vue-property-decorator'
import BaseResourceSelector from "@/components/base/BaseResourceSelector.vue"
import { ApiResource, EntityResource } from '@/resources';
import UserAvatar from './UserAvatar.vue';

@Component({inheritAttrs: false, components : {BaseResourceSelector, UserAvatar}})
export default class UserSelector extends Vue {
    @Prop({default: false}) readonly showAdmin !: boolean
    @Prop({default: () => true}) filterFunction !: Function

    get usersAssociation() {
        return ApiResource.Instance.users;
    }

    name(er : EntityResource) {
        const data = er.data();
        return data.firstName + " " + data.lastName
    }
}
