
import { Component, Watch, Vue, Prop} from "vue-property-decorator";
import AsyncComputed from "vue-async-computed-decorator";
import { AssociationResource, MaintenanceItemPrototypeResource, MaintenanceItemTypeResource, MaintenanceSystemsResource, PropertyMaintenanceItemResource, PropertyMaintenanceSystemsResource, PropertyResource } from "@/resources";
import { Debounce } from "vue-debounce-decorator";
import MaintenanceItemName from "./MaintenanceItemName.vue";
import { State } from "ketting";

@Component({components : {MaintenanceItemName}})
export default class MaintenanceItemPrototypeSearch extends Vue {

  /**
   * 1) If adding part to prototype equipment OR 4) with PMI, adding missing single part
   * to equipment.
   */
  @Prop({}) maintenanceItemPrototype !: MaintenanceItemPrototypeResource

  /**
   * 2) If adding one or more parts to property equipment
   */
  @Prop({}) propertyMaintenanceItem !: PropertyMaintenanceItemResource

  /**
   * 3) If adding part/equipment to particular system
   */
  @Prop({}) propertyMaintenanceSystem !: PropertyMaintenanceSystemsResource

  /**
   * 4) If adding part/equipment to particular system which may not exist
   */
  @Prop({}) maintenanceSystem !: MaintenanceSystemsResource

  @Prop({}) buttonAttrs !: any

  @Prop({default: false}) equipmentOnly !: boolean

  search : string = ""
  searching : boolean = false
  searchResults : any = []
  adding : boolean = false
  addingError : string = ""
  itemsToAdd : any = []
  show : boolean = false
  
  apiSearchByNameAndType : AssociationResource<MaintenanceItemPrototypeResource[]> = new MaintenanceItemPrototypeResource().searchByNameAndType
  apiSearchByName : AssociationResource<MaintenanceItemPrototypeResource[]> = new MaintenanceItemPrototypeResource().searchByName
  apiPmsFindPrimary : AssociationResource<PropertyMaintenanceSystemsResource> = new PropertyMaintenanceSystemsResource().searchPrimarySystem

  @Watch('search', {immediate: true})
  @Debounce(300)
  onSearchChanged(newVal : any, oldVal : any) {
    // null on load
    if (newVal != oldVal && this.search != null) {
      this.$asyncComputed.doSearch.update()
    } 
  }

  $refs!: {
    typeSearch: any
  }

  @Watch('adding')
  addingUpdate() {
    this.$emit("adding", this.adding)
  }

  @Watch('show', {immediate: true})
  dialogClosed(newVal : any) {
    // clear cached items and items added if closing dialog
    if (!newVal) {
      this.itemsToAdd.splice(0)
    } else {

      // init the default list (load all)
      this.search = ""
      this.$asyncComputed.doSearch.update()
    }
  }

  getSystems(itemType : any) {
    var systems = itemType.maintenanceSystems.map((ms : any) => ms.name)
    return systems.join(" / ")
  }

  /**
   * We handle multiple if adding multiple parts to equipment or given a system, single only
   * if we have the prototype and a pmi which is equipment.
   */
  get multiple() {
    return true // TODO issues with switching model from value to array.. !(this.maintenanceItemPrototype && this.propertyMaintenanceItem)
  }

  async getTypeIds() : Promise<number[]> {
    var typeIds = []

    // 1) adding part to prototype equipment, restrict types by allowed part types
    if (this.maintenanceItemPrototype && !this.propertyMaintenanceItem) {
      await this.maintenanceItemPrototype.get()

      let itemTypePartResources = await new MaintenanceItemTypeResource(this.maintenanceItemPrototype.data().typeId).parts.getAssociation()
      typeIds = itemTypePartResources.map((r) => r.data().id)
    }
    // 4) adding specific SINGLE missing part to equipment, restrict to type of missing part prototype
    else if (this.maintenanceItemPrototype && this.propertyMaintenanceItem) {
      await this.maintenanceItemPrototype.get()

      let itemTypePartResource = await new MaintenanceItemTypeResource(this.maintenanceItemPrototype.data().typeId)
      typeIds = [itemTypePartResource.data().id]
    }
    // 2) adding part to property equipment, restrict types by prototype allowed parts
    else if (this.propertyMaintenanceItem && !this.maintenanceItemPrototype) {
      let prototypeResource = await this.propertyMaintenanceItem.prototype.getAssociation()
      let itemTypePartResources = await new MaintenanceItemTypeResource(prototypeResource.data().typeId).parts.getAssociation()
      typeIds = itemTypePartResources.map((r) => r.data().id)
    }

    // 3/4) If adding part/equipment to particular system, fetch rolled up types for system
    else if (this.propertyMaintenanceSystem || this.maintenanceSystem) {
      let maintenanceSystem = this.maintenanceSystem ? this.maintenanceSystem : await this.propertyMaintenanceSystem.maintenanceSystem.getAssociation()
      let itemTypePartResources = await maintenanceSystem.maintenanceItemTypesRolledUp.getAssociation()
      typeIds = itemTypePartResources.map((r) => r.data().id)
    }

    return typeIds
  }

  mapResourceToResult(r : MaintenanceItemPrototypeResource, idx : number) {
    return {
        id : idx,
        name : r.data().name,
        isEquipment : r.data().isEquipment,
        hasSystems : r.data().maintenanceSystems,
        systemsName : this.getSystems(r.data()),
        maintenanceSystemId : "",
        typeName : r.data().typeName,
        uri : r.uriFull,
      }
  }

  mapResourcesToResults(resources : MaintenanceItemPrototypeResource[], expand : boolean) {
    let results : any[] = []
    if (!expand) {
      results = resources.map((r, idx) => this.mapResourceToResult(r, idx))
    } 
    // map each resource, but expand for multiple systems
    else {
      resources.forEach((r) => {
        if (r.data().maintenanceSystems && r.data().maintenanceSystems.length > 1) {
          r.data().maintenanceSystems.forEach((ms:any) => {
            var result = this.mapResourceToResult(r, results.length)
            result.maintenanceSystemId = ms.id
            result.systemsName = ms.name

            results.push(result)
          })
        } else {
          var result = this.mapResourceToResult(r, results.length)
          result.maintenanceSystemId = r.data().maintenanceSystems[0].id
          results.push(result)
        }
      })
    }

    results.sort((a:any, b:any) => {
      let sCompare = a.systemsName.localeCompare(b.systemsName)
      let tCompare = a.typeName.localeCompare(b.typeName)
      let pCompare = a.name.localeCompare(b.name)
      if (sCompare != 0) return sCompare
      if (tCompare != 0) return tCompare
      return pCompare
    })

    return this.equipmentOnly ? results.filter((r:any) => r.isEquipment) : results
  }

  @AsyncComputed({shouldUpdate() {return false}}) // FOR DEBOUCE
  async doSearch() {
    this.searching = true

    if (this.show && this.$refs['typeSearch'] && !this.search) {
      this.$refs['typeSearch'].cachedItems = []
    }

    // Get type ids of item type parts
    var typeIds = await this.getTypeIds();
    var searchResources
    if (typeIds && typeIds.length) {
      // @ts-ignore
      searchResources = await this.apiSearchByNameAndType.getAssociation({name: this.search ? this.search : "", typeIds: typeIds, projection: "maintenanceItemPrototypeDetail"}, false)
    }
    else {
      searchResources = await this.apiSearchByName.getAssociation({name: this.search ? this.search : "", projection: "maintenanceItemPrototypeDetail"}, false)
    }

    // if not adding a prototype, we expand results for those with multiple systems
    var expandSystems = !(this.maintenanceItemPrototype)
    this.searchResults = this.mapResourcesToResults(searchResources, expandSystems)
    
    // TODO see https://github.com/vuetifyjs/vuetify/issues/11365
    if (this.show) {
      this.$refs['typeSearch'].cachedItems = [...this.searchResults, ...this.itemsToAdd]
    }
    
    this.searching = true
  }

  addItems(itemsToAdd : any) {

    this.adding = true

    // 1) adding part to prototype equipment, restrict types by allowed part types
    if (this.maintenanceItemPrototype && !this.propertyMaintenanceItem) {
      var uris = itemsToAdd.map((i:any) => i.uri)
      this.maintenanceItemPrototype.parts.addTo(uris)
      .then(() => {
        this.show = false
        this.$emit("updated", true)
      })
      .catch((reason) => {
        if (reason && reason.status && reason.status == "409") {
          this.addingError = "Part already exists."
        }
      })
      .finally(() => this.adding = false)
    }

    // 2) adding parts to property equipment, restrict types by prototype allowed parts
    // 3+4) If adding part/equipment to particular property system, fetch rolled up types for system
    else if (this.propertyMaintenanceItem || this.propertyMaintenanceSystem) {

      var itemPromises : Promise<State<any>>[] = itemsToAdd.map((i:any) => this.addPropertyItem(i))
      Promise.allSettled(itemPromises).then((results) => {
        var success = true
        results.forEach((r, idx) => {
          if (r.status == "rejected") {
            console.error("Failed to create part '" + itemsToAdd[idx].name + "': " + r.reason)
            success = false
            this.addingError = "Error adding one or more parts."
          }
        })
        this.show = !success  
        this.adding = false

        this.$emit("updated", true)
      })  
    }

  }

  async addPropertyItem(i : any) {

    var pmsUri
    // if we are adding a part to existing equipment, use the pms of the equipment
    if (this.propertyMaintenanceItem) {
      let pms = await this.propertyMaintenanceItem.propertyMaintenanceSystem.getAssociation()
      pmsUri = new PropertyMaintenanceSystemsResource(pms.data().id).uriFull
    }
    // else we use the rolled up system to get the property, and locate the primary
    // pms for the ms of the item prototype
    else if (this.propertyMaintenanceSystem) {
      // fetch property for prop pms
      let propertyResource = await this.propertyMaintenanceSystem.property.getAssociation()
      
      // fetch the primary pms for the item ms
      // TODO... shoudl prompt user about this
      try {
        let pms = (await this.apiPmsFindPrimary.getAssociation({pid: propertyResource.data().id, msid: i.maintenanceSystemId}))
        pmsUri = new PropertyMaintenanceSystemsResource(pms.data().id).uriFull
      } 
      catch (e:any) {
        // pms doesn't exist, create
        if (e?.status == "404") {
          try {
            pmsUri = (await new PropertyMaintenanceSystemsResource().post({
              property : new PropertyResource(propertyResource.data().id).uriFull,
              maintenanceSystem : new MaintenanceSystemsResource(i.maintenanceSystemId).uriFull
            })).uri
          }
          catch (e2: any) {
              console.error("Could not create new PMS for pid: " + propertyResource.data().id + ", msid:" + i.maintenanceSystemId, e2);
              throw e2
          }
        }
      }
    }

    var promise = new PropertyMaintenanceItemResource().post({
        prototype: i.uri,
        propertyMaintenanceSystem : pmsUri 
    })

    // if adding parts to equipment, post now and return 
    // add as part promise
    if (this.propertyMaintenanceItem) {
      var newPmiState = await promise
      return this.propertyMaintenanceItem.parts.addTo([newPmiState.uri])
    }
    // else return post promise
    else {
      return promise
    }
  }

  removeItemToAdd(item : any) {
    const index = this.itemsToAdd.findIndex((i : any) => i.id == item.id)
    if (index >= 0) this.itemsToAdd.splice(index, 1)
  }
}
