
import {Vue, Component} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import ClientNewForm from '../clients/ClientNewForm.vue'
import PropertySearchSelectorPanel from './PropertySearchSelectorPanel.vue'
import PropertyClientAddClientSearch from './PropertyClientAddClientSearch.vue'
import { ApiResource, ClientResource, PropertyResource } from '@/resources'
import PropertyAddForm from './PropertyAddForm.vue'
import PropertyComplexAdd from '@/util/propertyComplexAdd'

@Component({components: {ClientNewForm, PropertyAddForm, BaseDialog, PropertySearchSelectorPanel, PropertyClientAddClientSearch}})
export default class PropertyClientAddBothDialog extends Vue { 

  step : number = 1
  propertyFormValid : boolean = false
  apiResource : ApiResource = ApiResource.Instance
  
  data : PropertyComplexAdd = new PropertyComplexAdd();
  adding : boolean = false
  error : any = null

  created() {
    this.step = 1
    this.data.initPropertyAndClient(this);
  }

  gotoProperty(p : PropertyResource) {
    if (p) {
      this.$router.push({name:'property', params: {pid:p.data().id}})
    }
  }

  setClient(c : ClientResource | null) {
    this.data.client = c ? c.uriFull : null
  }

  async doTheDeed() {

    this.adding = true
    try {

      // post
      let state = await new PropertyResource("addComplex").post(this.data)
      
      // close up shop
      this.$emit('input', false)

      // go to the place
      this.$router.push({name:"property", params:{pid:state.data.id}})
    }
    catch (e : any) {
      this.error = e.message ? e.message : "Could not create job, unknown error."
    }
    finally {
      this.adding = false
    }
  }
  

}
