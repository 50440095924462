
import { Component, Prop } from "vue-property-decorator";
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import BaseYesNoDialog from "@/components/base/BaseYesNoDialog.vue"
import { BasePropertyMaintenanceItemComponent, RequiredProjections } from "../base/BaseMixins";
import MediaImageStrip from "@/components/media/MediaImageStrip.vue"
import JsonModelDisplayComponent from "../maintenanceSystems/JsonModelDisplayComponent.vue";
import { MaintenanceItemPrototypeResource, MaintenanceItemTypeResource, MaintenanceItemTypeSchemaResource, PropertyMaintenanceItemResource, SystemMediaResource } from "@/resources";
import MaintenanceItemFormDialog, {MODE_ADD_EDIT_PROPERTY_ITEM} from "@/components/maintenanceSystems/MaintenanceItemFormDialog.vue";

@RequiredProjections("propertyMaintenanceItemDetail")
@Component({components:{JsonModelDisplayComponent, MaintenanceItemFormDialog, BaseOptionsMenu, MediaImageStrip, BaseYesNoDialog}})
export default class PropertyMaintenanceItemDialog extends BasePropertyMaintenanceItemComponent {
    @Prop({ required : true, default: false}) readonly value!: boolean

    editDialog : boolean = false
    deleteDialog : boolean = false
    deleteError : any = null
    loadingDetails : boolean = false
    deleting : boolean = false
    hoverParent : boolean = false
    formAttributes : any = {}
    jsonSchema : any = null
    model : any = null
    parts : any = []
    parent : PropertyMaintenanceItemResource |  null = null

    gotoPart(part : PropertyMaintenanceItemResource) {
      this.$emit("changed", part)
    }

    async postEntityUpdate() {
      this.loadingDetails = true
      var prototype = await this.resource.prototype.getAssociation({}, false)
      var schema = await prototype.maintenanceItemTypeSchema.getAssociation({}, false)
      this.parts = await this.resource.parts.getAssociation({projection: "propertyMaintenanceItemDetail"}, false)
      this.jsonSchema = schema.data().jsonSchema
      this.model = {...prototype.data().prototypeModel, ...this.rdata.model}

      // try parent
      this.parent = null
      try {
        this.parent = await this.resource.parent.getAssociation({projection: "propertyMaintenanceItemDetail"})
      } 
      catch(e) {
        // ignore
      }

      this.loadingDetails = false
    }


    get deleteResource() {
      return new PropertyMaintenanceItemResource(this.rdata.id)
    }

    get isEquipment() {
      return this.rdata.prototype.isEquipment
    }

    get mediaUploadUrl() {
      return SystemMediaResource.mediaUploadUrl
    }

    get typeSuffixName() {
      return this.isEquipment ? 'equipment' : 'part'
    }

    deleteItem() {
      this.deleteError = null
      this.deleteDialog = true
    }

    async editItem() {

      // get prototype
      // TODO again, fucked up association resource
      var itemPrototypeResource = await this.resource.prototype.getAssociation({}, false)
      itemPrototypeResource = new MaintenanceItemPrototypeResource(itemPrototypeResource.data().id)
      await itemPrototypeResource.get()

      // get current prototype schema
      var currentSchemaResource = await itemPrototypeResource.maintenanceItemTypeSchema.getAssociation({}, false)

      // get prototype type 
      // TODO, this returns association rel resource "http://localhost:8080/api/maintenanceItemPrototypes/3/maintenanceItemType", we need
      // the item type resource 
      // TODO should be some way to streamline this... as it affects a number of things
      var itemTypeResource = await itemPrototypeResource.maintenanceItemType.getAssociation({}, false)
      itemTypeResource = new MaintenanceItemTypeResource(itemTypeResource.data().id)
      await itemTypeResource.get(false)

      // get latest prototype schema (may be newer one)
      const typeId = itemTypeResource.data().id
      const schemaVersion = itemTypeResource.data().latestVersion
      var latestSchemaResource = new MaintenanceItemTypeSchemaResource(typeId + "-" + schemaVersion)
      await latestSchemaResource.get(false)
      
      this.formAttributes = {
          value : true,
          mode : MODE_ADD_EDIT_PROPERTY_ITEM,
          propertyMaintenanceItemResource : this.resource,
          maintenanceItemTypeResource : itemTypeResource,
          maintenanceItemPrototypeResource : itemPrototypeResource,
          latestSchemaResource : latestSchemaResource,
          currentSchemaResource : currentSchemaResource,
        }

        this.editDialog = true
    }

    async doDeleteItem() {
      this.deleting = true

      // check if this has a parent, if so detach first
      try {
        var parentResource = await this.resource.parent.getAssociation()

        // parent exists, remove from parent parts
        await parentResource.parts.detachFrom(this.resource)
      } catch (e: any) {
        if (e?.status !== 404) {
          this.deleteError = "Error trying to remove this part from the parent equipment."
          return
        }
      }

      // now delete
      try {
        await this.resource.delete()

        this.$emit('input', false)
        this.deleteDialog = false
      }
      catch (e: any) {
        console.error(e)
        if (e?.status === 409) {
          this.deleteError = "Can not delete this item as there is another one dependent upon it.  Please delete that one first then try again. (409)"
        } else {
          this.deleteError = e
        }
      }
      finally {
        this.deleting = false
      }
      
        
  }

}
