
import { Component, Prop} from "vue-property-decorator"
import { BaseAppointmentComponent, RequiredProjections } from "../base/BaseMixins";
import BaseSchedulingEntry from "./BaseSchedulingEntry.vue";

@RequiredProjections("appointmentDetail")
@Component({components : {BaseSchedulingEntry}})
export default class SchedulingAppointment extends BaseAppointmentComponent {

  @Prop({required: true}) readonly footer !: string
  @Prop({default: false}) readonly showLeadIndicator !: boolean
  @Prop({default: false}) readonly singleLine !: boolean

  get title() {
    return this.singleLine 
        ? this.propertyAddress 
        : (this.rdata.jobs && this.rdata.jobs.length > 1 ? "Multiple jobs" : this.rdata.jobs[0].name)
  }
 
}
