
import {Component} from 'vue-property-decorator'
import {FormsMetadataResource} from "@/resources"
import { BaseHealthReportComponent, RequiredProjections } from '@/components/base/BaseMixins'

@RequiredProjections("healthReportDetail")
@Component({components: {}})
export default class HealthStatusChip extends BaseHealthReportComponent {

  get statusValues() {
    return FormsMetadataResource.Instance.getHalOptions("healthreport", "status")
  }

  get status() {
    return this.statusValues.find((s:any) => s.value === this.rdata.status)
  }

  get statusText() {
    return this.status.text
  }

}
