
import { Component, Prop } from "vue-property-decorator"
import {InstantUpdateFieldMixin} from "@/components/forms/FormsMixin"

@Component({ components: {}, inheritAttrs: false })
export default class InstantUpdateEnumSelector extends InstantUpdateFieldMixin {
 
  @Prop() itemsFilter !: Function
  @Prop() color !: string
  @Prop() backgroundColor !: string
  

  get halPropsObject() {
    const halProps = this.halProps(this.propName, this.$attrs);
    if (this.itemsFilter) {
      halProps.items = this.itemsFilter(halProps.items)
    }
    return halProps
  }

}
