
import { Component } from "vue-property-decorator"
import BaseHtmlEditor from "../base/BaseHtmlEditor.vue";
import { BaseAppointmentComponent, RequiredProjections } from "../base/BaseMixins";
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue";
import BasePanel from "../base/BasePanel.vue";
import PropertyAddress from "../properties/PropertyAddress.vue";
import BaseSchedulingEntry from "./BaseSchedulingEntry.vue";
import InstantUpdateEnumSelector from "../forms/InstantUpdateEnumSelector.vue";
import ContactsList from "../contacts/ContactsList.vue";
import ContactFormDialog from "../contacts/ContactFormDialog.vue";
import InstantUpdateTextArea from "../forms/InstantUpdateTextArea.vue";
import { AppointmentResource, ContactResource } from "@/resources";
import AppointmentEditDialog from "./AppointmentEditDialog.vue";

@RequiredProjections("appointmentDetail")
@Component({components : {ContactFormDialog, AppointmentEditDialog, BaseSchedulingEntry, BasePanel, BaseOptionsMenu, BaseHtmlEditor, PropertyAddress, InstantUpdateEnumSelector, InstantUpdateTextArea, ContactsList}})
export default class AppointmentDetailsPanel extends BaseAppointmentComponent {

  dateTimeDialog : boolean = false
  hideDateTime : boolean = false
  selectedAppointment !: AppointmentResource
  contactDialog : boolean = false
  selectedContact !: ContactResource | undefined

  showDateTimeDialog(hideDateTime : boolean) {
    this.hideDateTime = hideDateTime
    this.selectedAppointment = this.resource.fullResource()
    this.dateTimeDialog = true
  }

  editContact(contact : ContactResource) {
    this.selectedContact = contact
    this.contactDialog = true
  }

  appointmentStatusFilter(items : any[]) {
    return items.filter((e:any) => !this.isJobClosed(e.value))
  }

  isJobClosed(jobStatus : string) {
    return AppointmentResource.STATUSES_FINISHED.includes(jobStatus)
  }

  gotoProperty() {
    // TODO simplify.. just url with job id ?
    const pid = this.rdata.jobs[0].property.id
    this.$router.push({ name: 'property', params: {pid : pid}})
  }

  gotoClient() {
    // TODO simplify.. just url with job id ?
    const cid = this.rdata.jobs[0].property.client.id
    this.$router.push({ name: 'client', params: {cid : cid}})
  }

  get canEdit() {
    return ![AppointmentResource.STATUS_CANCELLED, AppointmentResource.STATUS_COMPLETED].includes(this.rdata.status)
  }

  get contactWatchUris() {
    return this.contacts.map((c:any) => '/api/contacts/' + c.id)
  }

  get contacts() {
    const contacts = []
    if (this.rdata.jobs[0].property.client && this.rdata.jobs[0].property.client.contacts) {
      var cid = this.rdata.jobs[0].property.client.id

      contacts.push(...this.rdata.jobs[0].property.client.contacts.map((c:any) => ({...c, cid:cid})))
    } else if (this.rdata.jobs[0].property.contacts) {
      var pid = this.rdata.jobs[0].property.id

      contacts.push(...this.rdata.jobs[0].property.contacts.map((c:any) => ({...c, pid:pid})))
    }
    return contacts
  }

  get emailSubject() {
    return "Appointment for " + this.rdata.jobs[0].name
  }

}
