
import { Component } from "vue-property-decorator"
import {InstantUpdateFieldMixin} from "@/components/forms/FormsMixin"
import BaseHtmlEditor from "../base/BaseHtmlEditor.vue";

@Component({ components: {BaseHtmlEditor}, inheritAttrs: false })
export default class InstantUpdateHtmlTextArea extends InstantUpdateFieldMixin {

  get halPropsObject() {
    return this.halProps(this.propName, this.$attrs);
  }
}
