
import {Component} from 'vue-property-decorator'
import {FormsMetadataResource} from "@/resources"
import { BaseMaintenanceJobComponent } from '../base/BaseMixins'
import JobStatusChip from './JobStatusChip.vue'


@Component({components: {JobStatusChip}})
export default class JobSummaryBar extends BaseMaintenanceJobComponent {

  hover : boolean = false

  get statusValues() {
    return FormsMetadataResource.Instance.getHalOptions("maintenancejob", "status")
  }

  get status() {
    return this.statusValues.find((s:any) => s.value === this.rdata.status).text
  }

  gotoJob() {
    this.$router.push({ name: 'job', params: {jid:this.rdata.id, pid : this.rdata.property_id, pmsId: this.rdata.propertyMaintenanceSystem_id}})
  }

}
