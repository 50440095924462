
import { HealthReportResource, MaintenanceJobResource, MaintenanceLogResource, PropertyMaintenanceSystemsResource, PropertyResource, SettingsResource } from '@/resources';
import AsyncComputed from 'vue-async-computed-decorator';
import {Component, Prop} from 'vue-property-decorator'
import BaseDialog from '../base/BaseDialog.vue';
import { BaseMaintenanceJobComponent, RequiredProjections } from '../base/BaseMixins';
import JobLogForm from './JobLogForm.vue';
import { BaseHealthReportComponent } from '../base/BaseMixins';
import HealthStatusChip from '../healthreports/HealthStatusChip.vue';
import HealthReportFormDialog from '../healthreports/HealthReportFormDialog.vue';
import { DateTime } from 'luxon';
import ClientName from '../clients/ClientName.vue';
import PropertyAddress from '../properties/PropertyAddress.vue';
import BaseDateTimeInput from '../base/BaseDateTimeInput.vue';
@RequiredProjections("*")
@Component({components: {BaseDateTimeInput, ClientName, PropertyAddress, BaseDialog, HealthReportFormDialog, JobLogForm, HealthStatusChip, BaseHealthReportComponent}})
export default class JobCloseCancelDialog extends BaseMaintenanceJobComponent { 

  @Prop({default: false}) cancelling !: boolean

  actualDuration : number = 0
  logFormModel : any = null
  closing : boolean = false
  schedulingNext : boolean = false
  jobHealthReportResource : HealthReportResource | null = null
  pmsResource !: PropertyMaintenanceSystemsResource
  propertyResource !: PropertyResource 
  editHealthDialog : boolean = false
  defaultLogModel !: any

  get buttonPrefix() {
    return this.cancelling ? "Cancel" : "Close"
  }

  get jobId() {
    return this.rdata.id
  }

  get hrUriPatternsToWatch() {
    if (!this.pmsResource) return []
    return ['/api/jobs/' + this.rdata.id + '/healthReport', '/api/propertyMaintenanceSystems/' + this.pmsResource.data().id + '/healthReports']
  }

  healthSummaryTitle(summary : any) {
    const s = this.healthSummaryStatus(summary)
    switch(s) {
      case "unknown":
        return "System Health status"
      case "existing":
        return "System health status"
      case "new":
        return "Current system health status"
    }
  }

  healthSummaryDescription(summary : any) {
    const s = this.healthSummaryStatus(summary)
    switch(s) {
      case "unknown":
        return "The health status for the job maintenance system is currently <b>unknown</b>. Click on the label to update."
      case "new":
        return "The current health status for the job maintenance system is shown above.  Click on the label to update."
    }
  }

  /**
   * Returns "unknown" if the system health status is unknown (not set), "existing" if a health status has 
   * been set on the job, or "new" if a system health status exists on the PMS but has not been overidden
   * on the job.
   * @param summary 
   */
  healthSummaryStatus(summary : any) : "existing" | "unknown" | "new" {
    if (summary.id < 0 || summary.status == "UNKNOWN") {
      return "unknown"
    }
    return (summary.job && summary.job.id == this.rdata.id) ? "existing" : "new"
  }

  get title() {
    return (this.cancelling ? "Cancel" : "Close") + " : " + (this.rdata ? this.rdata.name : " job")
  }

  get stateColor() {
    return this.cancelling ? "error" : "primary"
  }

  get description() {
    return (this.cancelling ? "Reason for cancellation" : "Closing log entry") + " (optional)"
  }

  openEditHealthDialog() {

    // already has one ? just show the dialog
    if (this.jobHealthReportResource) {
      this.editHealthDialog = true
    }

    // else try to load
    this.jobHealthReportResource = null
    this.resource.healthReport.getAssociation().then((results) => {
      this.jobHealthReportResource = new HealthReportResource(results.data().id)
      this.editHealthDialog = true
    })
    .catch((e) => {
      if (e?.status === 404) {
        // not found, new
        this.editHealthDialog = true
      }
      else {
        console.error(e)
      }
    })
   
  }

  updateLogModel(logModel : any) {
    this.logFormModel = logModel
  }

  get endDate() {
    // end date now, unless now is < start
    var now = DateTime.now()
    var start = DateTime.fromISO(this.rdata.startDate)
    return now < start ? start : now
  }

  close(scheduleNext : boolean) {
    this.schedulingNext = scheduleNext

    const saveLog = this.logFormModel && this.logFormModel.content

    const promises : Promise<any>[] = []
    
    if (saveLog) {
      promises.push(new MaintenanceLogResource().post(this.logFormModel))
    }

    // TODO error
    this.closing = true

    // Note we set the end date here
    promises.push(this.resource.mergePatch({
        actualDuration : this.actualDuration,
        endDate : this.endDate.setZone('utc').toISO(),  // ensure GMT
        status : this.cancelling ? MaintenanceJobResource.STATUS_CLOSED_CANCELLED : MaintenanceJobResource.STATUS_CLOSED_COMPLETED
    }))

    Promise.all(promises).then(() => {
      this.$emit("close", scheduleNext)
    }).finally(() => {
      this.closing = false
      this.schedulingNext = false
    })
  }

  @AsyncComputed()
  async loadExtras() {
    
    // prep a default job log model, fetching the pms
    this.defaultLogModel = {...SettingsResource.defaultObject("maintenanceLog"),
              job : this.resource.resource.uri}

    // job log pms and hr pms are same as job pms, ensure we have entity uri
    this.pmsResource = await this.resource.propertyMaintenanceSystem.getAssociation()
    this.pmsResource = new PropertyMaintenanceSystemsResource(this.pmsResource.data().id)
    await this.pmsResource.get()

    // set log pms
    this.defaultLogModel.propertyMaintenanceSystem = this.pmsResource.uriFull

    // set pms and property used in hr form
    this.propertyResource = await this.resource.property.getAssociation()
    this.propertyResource = new PropertyResource(this.propertyResource.data().id)

    try {
      this.jobHealthReportResource = await this.resource.healthReport.getAssociation()
      this.jobHealthReportResource = new HealthReportResource(this.jobHealthReportResource.data().id)
    } catch (e:any) {
      if (e?.status !== 404) {
        console.error("Could not load existing job health report.")
        console.error(e)
      }
    }
  }
}
