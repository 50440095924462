
import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import PropertySearchSelectorPanel from './PropertySearchSelectorPanel.vue'
import { ApiResource, ClientResource, PropertyResource } from '@/resources'
import PropertyAddForm from './PropertyAddForm.vue'
import JobForm from "@/components/jobs/JobForm.vue"
import PropertyComplexAdd from '@/util/propertyComplexAdd'
import ClientNewForm from '../clients/ClientNewForm.vue'
import PropertyClientAddClientSearch from './PropertyClientAddClientSearch.vue'
import AsyncComputed from 'vue-async-computed-decorator'

@Component({components: {JobForm, ClientNewForm, PropertyAddForm, BaseDialog, PropertyClientAddClientSearch, PropertySearchSelectorPanel}})
export default class PropertyAddUnscheduledJobDialog extends Vue { 

  @Prop({default: () => null, required: false}) startDate : any
  @Prop({default: () => null, required: false}) endDate : any

  data : PropertyComplexAdd = new PropertyComplexAdd();
  step : number = 1
  propertyFormValid : boolean = false
  jobFormValid : boolean = false
  clientFormValid : boolean = false
  apiResource : ApiResource = ApiResource.Instance
  adding : boolean = false
  error : any = null

  get usingExistingProperty() {
    return !!this.data.property
  }
  get usingExistingClient() {
    return !!this.data.client
  }

  @Watch("data.property")
  propertyChanged() {
    this.data.initClient(this)
  }

  /**
   * Job step is editable if existing property selected, OR
   * new property is selected and client is existing or new is valid
   */
  get jobStepEditable() {
    return (this.usingExistingProperty) || 
           (this.propertyFormValid && (this.usingExistingClient || this.clientFormValid))
  }

  get jobStep() {
    // if we have a property, we are skipping all other steps
    if (this.usingExistingProperty) {
      return '2'
    }
    // if no property, but client, then no client step
    if (!this.usingExistingProperty && this.usingExistingClient) {
      return '4'
    }
    return '5'
  }

  created() {
    this.step = 1
    this.data.initPropertyAndClient(this)
    this.data.initJob(this);
  }

  /**
   * Set only if a property is selected
   */
  @AsyncComputed()
  async selectedPropertyResource() {
    var p = null
    if (this.data.property) {
      p = new PropertyResource(this.data.property)
      await p.get()
    }
    return p
  }

  get jobTemplatesAssociation() {
    return new ApiResource().jobTemplates
  }

  setProperty(p : PropertyResource | null) {
    this.data.property = p ? p.uriFull : null

    // goto job step
    if (p)  {
      // @ts-ignore
      this.$nextTick(() => {this.step = this.jobStep})
    }
  }
  setClient(c : ClientResource | null) {
    this.data.client = c ? c.uriFull : null
  }

  updateAppt(appt: any) {
    console.log(appt)
  }


  async doTheDeed() {

    this.adding = true
    try {
      // wipe prop and client if using existing
      if (this.data.property) {
        this.data.newProperty = null
        this.data.newClient = null
      }

      // post
      await new PropertyResource("addComplex").post(this.data)
      
      // close up shop
      this.$emit('input', false)
    }
    catch (e : any) {
      this.error = e.message ? e.message : "Could not create job, unknown error."
    }
    finally {
      this.adding = false
    }
  }
}
