
import {Component, Prop} from 'vue-property-decorator'
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import UserSelector from '../user/UserSelector.vue';
import { DateTime, Duration, Interval } from 'luxon';
import { cloneDeep, set, tap } from 'lodash-es';
import { UserResource } from '@/resources';
import BaseHtmlEditor from '../base/BaseHtmlEditor.vue';
import BaseTimePicker from '../base/BaseTimePicker.vue';

@Component({components: {UserSelector, BaseHtmlEditor, BaseTimePicker}})
export default class AppointmentEditForm extends ValueBasedFormsMixin { 

  startDateMenu : boolean = false
  startTimeMenu : boolean = false
  endTimeMenu : boolean = false

  @Prop({default: false}) hideDateTime !: boolean
  @Prop({default: "6:30"}) minStartTime !: string  // TODO use system value same as calendar
  @Prop({default: "18:00"}) maxEndTime !: string
  @Prop({default: 15}) minTimeInterval !: number
  @Prop({default: false}) hideNotes !: boolean

  getHalFormName() : string { return "appointment" }

  async updateLead(evt : any) {

    // update the lead
    this.doUpdate('appointmentLead', evt)

    // fetch the default calendar and update the calendar id
    const userResource = new UserResource(evt)
    const userState = await userResource.get(false)
    const calendarUrl = userState.data.settings.appointmentCalendarUrl
    
    // TODO clean this up... calendar URI are only under user... make sense ?
    const calendars = await userResource.calendars.getAssociation()
    const appointmentCalendar = calendars.find((c:any) => c.uriFull == calendarUrl) 
    if (appointmentCalendar) {
      this.doUpdate('calendarId', appointmentCalendar.data().id)
    }
  }

  get dateISOFull() {
    return DateTime.fromISO(this.value.startDate).toFormat('EEEE, MMMM d, yyyy')
  }

  /**
   * Returns start/end date for use in the picker (YYYY-MM-DD)
   */
  get dateISO() {
    return DateTime.fromISO(this.value.startDate).toISODate()
  }

  /**
   * Returns start time for use in the time picker (HH:MM in 24 hours)
   */
  get startTime() {
    return DateTime.fromISO(this.value.startDate).toLocaleString(DateTime.TIME_24_SIMPLE)
  }

  /**
   * Converts 24 time (HH:MM) to minutes
   * @param timeStr 
   */
  timeToMinutes(timeStr : string) {
    var parts = this.timeToParts(timeStr)
    return Duration.fromObject({hours: parts[0], minutes: parts[1]}).as('minutes')
  }

  get startTimeRules() {
    return [
      (startTime:string) => {
        return this.timeToMinutes(startTime) >= this.timeToMinutes(this.minStartTime) ? true : "Start time must be on or after " + this.time24ToReadable(this.minStartTime) + "."
      },
      (startTime:string) => {
        return this.timeToMinutes(startTime) < this.timeToMinutes(this.maxEndTime) ? true : "Start time must be less than " + this.time24ToReadable(this.maxEndTime) + "."
      }
    ]
  }

  get endTimeRules() {
    return [
      (endTime:string) => {
        return this.timeToMinutes(endTime) > this.timeToMinutes(this.minStartTime) ? true : "End time must be after " + this.time24ToReadable(this.minStartTime) + "."
      },
      (endTime:string) => {
        return this.timeToMinutes(endTime) <= this.timeToMinutes(this.maxEndTime) ? true : "End time must be on or before " + this.time24ToReadable(this.maxEndTime) + "."
      }
    ]
  }

  /**
   * Returns start time for use in the time picker (HH:MM in 24 hours)
   */
  get startTimeFull() {
    return DateTime.fromISO(this.value.startDate).toFormat("hh:mm a");
  }

  /**
   * Returns start time for use in the time picker (HH:MM in 24 hours)
   */
  get endTime() {
    return DateTime.fromISO(this.value.endDate).toLocaleString(DateTime.TIME_24_SIMPLE)
  }

  /**
   * Returns end time for use in the time picker (HH:MM in 24 hours)
   */
  get endTimeFull() {
    return DateTime.fromISO(this.value.endDate).toFormat("hh:mm a");
  }

  time24ToReadable(timeStr : string) {
    var parts = timeStr.split(":")
    var hours = parseInt(parts[0],10)
    var isPM = (hours >= 12)

    return (isPM ? hours-12 : hours) + ":" + parts[1] + " " + (isPM ? "PM" : "AM")
  }

  allowedMinutes(min : any) {
    return min % this.minTimeInterval == 0
  }

  timeToParts(timeStr : string) {
    if (!timeStr) {
      return [0,0]
    }

    var timeParts = timeStr.split(" ")
    var parts = timeParts[0].split(":")

    var hours = parseInt(parts[0],10)
    var ampm = timeParts[1]

    return [hours + (ampm == "PM" && hours != 12 ? 12 : 0), parseInt(parts[1],10)]
  }

  setStartTime(timeStr : string) {
    let parts = this.timeToParts(timeStr)
    
    let hours = parts[0]
    let minutes = parts[1]

    let existingStart = DateTime.fromISO(this.value.startDate)
    let existingEnd = DateTime.fromISO(this.value.endDate)
    let existingDuration = Interval.fromDateTimes(existingStart, existingEnd).length("minutes")

    let newStart = existingStart.set({
      hour : hours, 
      minute: minutes})
      let newEnd = newStart.plus({minutes: existingDuration})

    // ensure GMT
    let clonedAndMerged = cloneDeep(this.getValue())
    clonedAndMerged = tap(clonedAndMerged, v => set(v, 'startDate', newStart.setZone('utc').toISO()))
    clonedAndMerged = tap(clonedAndMerged, v => set(v, 'endDate', newEnd.setZone('utc').toISO()))
    this.$emit('input', clonedAndMerged)
  }

  setEndTime(timeStr : string) {
    let parts = this.timeToParts(timeStr)
    let hours = parts[0]
    let minutes = parts[1]

    let existingEnd = DateTime.fromISO(this.value.endDate)
    let newEnd = existingEnd.set({
      hour : hours, 
      minute: minutes})

    // ensure GMT
    this.doUpdate('endDate', newEnd.setZone('utc').toISO())
  }

  /**
   * Sets the full ISO start/end date and time given the iso date, and using the 
   * current start time.
   * @param isoDate 
   */
  setDateISO(isoDate : string) {

    // create temp date to pull yyyy/mm/dd
    var newDate = DateTime.fromISO(isoDate)
    
    // set start/end date to same date, keeping existing times
    var newStartISO = DateTime.fromISO(this.value.startDate).set({
      year : newDate.get('year'), 
      month: newDate.get('month'), 
      day: newDate.get('day')}
    )
    var newEndISO = DateTime.fromISO(this.value.endDate).set({
      year : newDate.get('year'), 
      month: newDate.get('month'), 
      day: newDate.get('day')}
    )

    // we can't call doUpdate repeatedly as it will overwrite the first
    // call, ensure GMT time
    var clonedAndMerged = cloneDeep(this.getValue())
    clonedAndMerged = tap(clonedAndMerged, v => set(v, 'startDate', newStartISO.setZone('utc').toISO()))
    clonedAndMerged = tap(clonedAndMerged, v => set(v, 'endDate', newEndISO.setZone('utc').toISO()))
    this.$emit('input', clonedAndMerged)

    this.startDateMenu = false
  }

}
