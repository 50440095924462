
import { MaintenanceItemPrototypeResource, PropertyMaintenanceItemResource, PropertyMaintenanceSystemsResource, PropertyResource } from "@/resources";
import { Component, InjectReactive, Prop, Watch } from "vue-property-decorator"
import { BasePropertyMaintenanceItemComponent, RequiredProjections } from "../base/BaseMixins";
import PropertyMaintenanceItemDialog from "./PropertyMaintenanceItemDialog.vue"
import PropertyMaintenanceItemAddPanel from "./PropertyMaintenanceItemAddPanel.vue";
import {MODE_ADD_MISSING_PART_TO_EQUIPMENT, MODE_ADD_PARTS_TO_EQUIPMENT, MODE_ADD_PARTS_TO_SYSTEM} from "./PropertyMaintenanceItemAddPanel.vue"

@RequiredProjections("propertyMaintenanceItemDetail")
@Component({components : {PropertyMaintenanceItemDialog, PropertyMaintenanceItemAddPanel}})
export default class PropertyMaintenanceItemsPanel extends BasePropertyMaintenanceItemComponent { 

  @InjectReactive() propertyResource !: PropertyResource
  @Prop({required: true}) propertyMaintenanceSystemResource !: PropertyMaintenanceSystemsResource

  @Prop({default: false}) showAdd !: boolean
  @Prop({default: false}) hideTitle !: boolean

  @Prop({default: () => {}}) addPanelProps !: any

  active : any[] =  []
  expanded : any[] = []
  editing : boolean = false
  noItemsAddMode : boolean = false
  unknownIdx : number = 0
  removingItem : any = {}
  addingItem : any = {}
  errorItem : any = {}

  itemDialog : boolean = false
  selectedItem : PropertyMaintenanceItemResource | null = null

  itemAddProps : any = {}
  addMenu : boolean = false
  addMenu_x : number = 0
  addMenu_y : number = 0

  showSearch : boolean = false
  unknownPartType !: MaintenanceItemPrototypeResource
  equipmentResource !: PropertyMaintenanceItemResource

  projectionSuffix = "?projection=propertyMaintenanceItemDetail"

  get isBusy() {
    return this.updating || Object.keys(this.removingItem).length > 0 || Object.keys(this.addingItem).length > 0
  }

  toggleExpand(item: any) {
    let idx = this.expanded.indexOf(item.id) 
    if (idx > -1) {
      this.expanded.splice(idx, 1)
    }
    else {
      this.expanded.push(item.id)
    }
  }

  @Watch("addMenu", {immediate: true})
  addMenuChanged() {
    // if the menu is closed, and no items
    // don't go into the edit mode
    if (this.loadedOnce && this.addMenu && this.noItems) {
      this.editing = false
    }
  }

  /**
   * Comparator to sort parts (by system, then type, then name)
   * @param a 
   * @param b 
   */
  partComparator(a : any, b: any) {
    // may not be a system if unknown
    if (a.systemName && b.systemName) {
      let systemCompare = a.systemName.localeCompare(b.systemName)
      if (systemCompare != 0) {
        return systemCompare
      }
    }

    let typeCompare = a.typeName.localeCompare(b.typeName)
    if (typeCompare != 0) {
      return typeCompare
    }
    return a.name.localeCompare(b.name)
  }

  /**
   * Creates a known part given the property maintenance item
   * @param part 
   */
  createKnownPart(itemPart : any, pid : any) {
    let partPrototype = itemPart.prototype
    
    let newPart : any = {
          id : 'p' + itemPart.id,
          iid : itemPart.id,
          pid : pid,
          isEquipment : partPrototype.isEquipment,
          isUnknown : false,
          isDisabled : itemPart.model?.isDisabled, // special field
          typeName: partPrototype.typeName,
          name : partPrototype.name, // name of part used
          systemName : pid ? null : itemPart.propertyMaintenanceSystem.maintenanceSystemName,
          numMissingParts : itemPart.missingParts.length,
          numKnownParts : 0,
          children : []
    }

    // do children and sort
    newPart.children = itemPart.parts.map((p:any) => this.createKnownPart(p, itemPart.id))
    newPart.children.sort(this.partComparator)

    // calculate missing parts
    itemPart.missingParts.forEach((p:any) => newPart.children.push(this.createUnknownPart(p, itemPart.id)));

    // calculate # of known parts
    newPart.numKnownParts = newPart.children.length - newPart.numMissingParts

    return newPart
  }


  /**
   * Creates an unknown part from the given prototype.
   * @param partPrototype 
   */
  createUnknownPart(partPrototype : any, pid : any) {
    this.unknownIdx++
    return {
        id : 'p-unknown-' + this.unknownIdx,
        pi_r : undefined,
        pid : pid,
        pp_r : new MaintenanceItemPrototypeResource(partPrototype.id), // used when adding and restricting types
        isEquipment : partPrototype.isEquipment,
        isUnknown : true,
        typeName: partPrototype.typeName,
        name : "Unknown", //partPrototype.name // name of missing part
    }
  }

  get allData() {
    return this.rdata
  }

  /**
   * Returns resources which are not children of other resources
   */
   get rootData() {
    // find resourcs that are not a part of any parts list
    return this.allData.filter((r:any) => {
      return !this.allData.find((r2:any) => {
        var pids = r2.parts.map((p:any) => p.id)
        return pids.includes(r.id)
      })
    })
  }

  get noItems() {
    return !this.loadedOnce || this.items.length == 0
  }

  get items() {
    var rootParts = this.rootData.map((d:any) => this.createKnownPart(d, null))
    rootParts.sort(this.partComparator) 

    // add attribute to differentiate system changes (for display)
    if (rootParts.length > 0) {
      let currSystem = rootParts[0].systemName
      for (let p of rootParts) {
        if (currSystem != p.systemName) {
          p.systemChanged = true
          currSystem = p.systemName
        } else {
          p.systemChanged = false
        }
      }
    }
    
    // if no items, no edit mode
    if (rootParts.length == 0) {
      this.editing = false
    }
    return rootParts
  }

  hasError(item : any) {
    return this.errorItem[item.id]
  }

  clearError(item : any) {
    this.$delete(this.errorItem, item.id)
  }

  addPartsToSystem(evt : any) {
    this.$set(this.addingItem, -1, true)
    this.addPartsToSystemAsync().then(() => {
      // required resources loaded
      this.showAddMenu(evt)
    })
    .catch((e:any) => {
      this.$set(this.errorItem, -1, "Server error: " + e?.status)
    })
    .finally(() => {
      this.$delete(this.addingItem, -1)
    })
  }
  async addPartsToSystemAsync() {
    await this.propertyMaintenanceSystemResource.get()

    this.itemAddProps = {
      mode: MODE_ADD_PARTS_TO_SYSTEM,
      propertyResource : this.propertyResource,
      pmsSearchContext : this.propertyMaintenanceSystemResource
    }
  }


  addPartsToEquipment(item: any, evt : any) {
    this.$set(this.addingItem, item.id, true)
    this.addPartsToEquipmentAsync(item).then(() => {
      // required resources loaded
      this.showAddMenu(evt)
    })
    .catch((e:any) => {
      this.$set(this.errorItem, item.id, "Server error: " + e?.status)
    })
    .finally(() => {
      this.$delete(this.addingItem, item.id)
    })
  }
  async addPartsToEquipmentAsync(item: any) {
    let itemParent = new PropertyMaintenanceItemResource(item.iid + this.projectionSuffix)
    await itemParent.get()

    this.itemAddProps = {
      mode: MODE_ADD_PARTS_TO_EQUIPMENT,
      propertyResource : this.propertyResource,
      itemParent : itemParent
    }
  }



  addUnknown(item: any, evt : any) {
    this.$set(this.addingItem, item.id, true)
    this.addUnknownAsync(item).then(() => {
      // required resources loaded
      this.showAddMenu(evt)
    })
    .catch((e:any) => {
      this.$set(this.errorItem, item.id, "Server error: " + e?.status)
    })
    .finally(() => {
      this.$delete(this.addingItem, item.id)
    })
  }
  async addUnknownAsync(item: any) {
    let itemParent = new PropertyMaintenanceItemResource(item.pid + this.projectionSuffix)
    let itemPrototype = item.pp_r

    await itemParent.get()
    await itemPrototype.get()

    this.itemAddProps = {
      mode: MODE_ADD_MISSING_PART_TO_EQUIPMENT,
      propertyResource : this.propertyResource,
      itemParent : itemParent,
      itemPrototype: itemPrototype,
    }
  }

  showAddMenu(evt : any) {
    this.addMenu = false
    evt.preventDefault()
    this.addMenu_x = evt.clientX
    this.addMenu_y = evt.clientY

    if (this.addPanelProps && this.addPanelProps['position-x']) {
      this.addMenu_x = this.addPanelProps['position-x']
    }

    this.$nextTick(() => {
      this.addMenu = true
    })
  }

  selectItem(item : any) {
    if (!this.editing) {
      this.selectedItem = new PropertyMaintenanceItemResource(item.iid + this.projectionSuffix)
      this.itemDialog = true
    }
  }

  removeItem(item : any) {
    this.$set(this.removingItem, item.id, true)

    this.removeItemAsync(item).then(() => {
      // update on server sse msg
    })
    .finally(() => {
      this.$delete(this.removingItem, item.id)
    })
  }

  recurseRemoveItemResource(item : any) {

    if (item.isUnknown) return

    let id = item.iid
    let idx = this.resources.findIndex(r => r.data().id == id)

    if (idx != -1) {
      this.resources.splice(idx, 1)

      // remove active status
      let aid = this.active.indexOf(id)
      if (aid != -1) {
        this.active.splice(aid, 1)
      }

      // remove any child parts
      for (let c of item.children) {
        this.recurseRemoveItemResource(c)
      }
    }
  }

  /**
   * Deletes the given part, first removing it from it's parent equipment (if one exists)
   * @param item 
   */
   async removeItemAsync(item : any) {
    const id = item.iid
    
    // if parent, detaching will delete
    let itemResource = new PropertyMaintenanceItemResource(id + this.projectionSuffix)
    if (item.pid) {
      try {
        let parentResource = new PropertyMaintenanceItemResource(item.pid + this.projectionSuffix)
        await parentResource.get()
        await parentResource.parts.detachFrom(itemResource.fullResource())

        // remove from this client, others will get an update
        this.recurseRemoveItemResource(item)
      }
      catch(e : any) {
        console.error(e)
        this.$set(this.errorItem, item.id, "Could not remove part from equipment: " + e?.status)

        throw e
      }
    }
    // else deleting top level equipment/part
    else {
      // delete part
      try {
        await itemResource.fullResource().delete()

        // remove from this client, others will get an update
        await this.recurseRemoveItemResource(item)
      }
      catch (e: any) {
        this.$set(this.errorItem, item.id, "Could not delete part: " + e?.status)
        throw e;
      }
    }
  }

  entityUpdated(event : string) {
    const [type, uri] = event.split(": ")
    if (type === 'PROPERTY-ITEM-CHANGE') {
      const resourceUriWithoutQueryParams = this.propertyResource.resource.uri.split("?")[0]
      if (resourceUriWithoutQueryParams.endsWith(uri)) {
        this.update()
      }
    }
  }

  beforeMount() { 
    this.$eventBus.on('entityUpdate', this.entityUpdated)
  }
  beforeUnmount() { 
    this.$eventBus.off('entityUpdate', this.entityUpdated)
  } // vue3
  beforeDestroy() {  
    this.$eventBus.off('entityUpdate', this.entityUpdated)
  } // vue2

}
