
import {Component, Vue, Watch} from 'vue-property-decorator'
import PropertyMap from "@/components/properties/PropertyMap.vue"
import { Debounce } from 'vue-debounce-decorator'
import AsyncComputed from 'vue-async-computed-decorator'
import { ClientResource, PropertyResource } from '@/resources'

// TODO we duplicate this property search behavior in the propsearch bar, adding appointments
// and some other places... 
@Component({components: {PropertyMap}})
export default class PropertySearchSelectorPanel extends Vue {

  search : string = ""
  propertyApi : PropertyResource = new PropertyResource()
  selectedIdx : any = null
  properties : PropertyResource[] = []

  @AsyncComputed({shouldUpdate() {return false}})
  async propertiesLoad () {
    if (!this.search) {
      this.properties.splice(0)
    }
    else {
      this.properties = [...await (await this.propertyApi.searchByAddressOrName).getAssociation({addressOrName: this.search})]
    }
  }

  @Watch("selectedIdx")
  selectedIdxChanged() {
    if (this.selectedIdx !== null && this.selectedIdx >= 0) {
      this.$emit("selected", this.properties[this.selectedIdx])
    }
    else {
      this.$emit("selected", null)
      
    }
  }

  get clientUtil() : any {
    return ClientResource
  }

  get propertyUtil() : any {
    return PropertyResource
  }

  getBusinessAndOrClientName(businessName : string, clientName : string) {
    if (clientName && clientName.trim() && businessName && businessName.trim() && clientName.trim() != businessName.trim()) {
      return businessName.trim() + " (" + clientName.trim() + ")"
    }
    else if (clientName && clientName.trim()) {
      return clientName.trim()
    }
    else if (businessName && businessName.trim()) {
      return businessName
    }
    return "No client file attached."
  }

  @Watch('search')
  @Debounce(300)
  onSearchChanged() {
    this.$emit("input", this.search)
    this.selectedIdx = undefined
    this.$asyncComputed.propertiesLoad.update()
  }

}
