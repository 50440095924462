
import { MaintenanceJobResource, PropertyResource } from '@/resources';
import { DurationLikeObject } from 'luxon';
import {Component, Prop} from 'vue-property-decorator'
import { BaseMaintenanceJobComponent, RequiredProjections } from "../base/BaseMixins";
import JobFormDialog from "@/components/jobs/JobFormDialog.vue";
import {MODE_COPY_FROM_JOB, MODE_ADD_EDIT} from "@/components/jobs/JobFormDialog.vue"

@RequiredProjections("maintenanceJobSummary")
@Component({components: {JobFormDialog}})
export default class UnscheduledJobActions extends BaseMaintenanceJobComponent {

  @Prop({default: false}) readonly value !: boolean
  @Prop({default: () => [] }) readonly multipleJobs !: MaintenanceJobResource[]
  @Prop({required: true}) readonly menuX !: any
  @Prop({required: true}) readonly menuY !: any

  deleting : boolean = false

  jobDialog : boolean = false
  jobDialogMode : number = 0
  jobProperty !: PropertyResource
  
  copyInit : boolean = false
  copyJob : MaintenanceJobResource | null = null

  editInit : boolean = false
  editJob : MaintenanceJobResource | null = null

  async doEdit() {
    this.editInit = true

    let pid = this.rdata.property_id ? this.rdata.property_id : this.rdata.property.id
    this.copyJob = null
    this.editJob = new MaintenanceJobResource(this.rdata.id)
    this.jobProperty = new PropertyResource(pid)
    await this.editJob.get()
    await this.jobProperty.get()
    
    this.editInit = false
    
    this.jobDialogMode = MODE_ADD_EDIT
    this.jobDialog = true

    this.$emit('input', false)
  }

  async doCopy() {
    this.copyInit = true

    let pid = this.rdata.property_id ? this.rdata.property_id : this.rdata.property.id
    this.editJob = null
    this.copyJob = new MaintenanceJobResource(this.rdata.id)
    this.jobProperty = new PropertyResource(pid)
    await this.copyJob.get()
    await this.jobProperty.get()
    
    this.copyInit = false
    
    this.jobDialogMode = MODE_COPY_FROM_JOB
    this.jobDialog = true

    this.$emit('input', false)
  }


  doDelete() {
    this.deleting = true

    let promises : Promise<any>[] = []
    if (this.multipleJobs.length) {
      this.multipleJobs.forEach(j => promises.push(j.delete()))
    } else {
      promises.push(this.resource.delete())
    }

    Promise.allSettled(promises).then(() => {
    }).finally(() => {
      this.deleting = false
      this.$emit('input', false)
    })
  }

  get isBumpAvailable() {
    return this.multipleJobs ? this.bumpAvailableMultiple(this.multipleJobs) : this.bumpAvailable
  }

  doBump(bumpValue : DurationLikeObject | string, i : any) {
    let promises : Promise<any>[] = []
    
    this.$set(this.bumpingProgress, i, true) 
    if (this.multipleJobs.length) {
      this.multipleJobs.forEach(j => promises.push(this.bumpJobPromise(j, bumpValue, i)))
    } else {
      promises.push(this.bumpJobPromise(this.resource, bumpValue, i))
    }

    Promise.allSettled(promises).then(() => {
    }).finally(() => {
      this.$set(this.bumpingProgress, i, false)
      this.$emit('input', false)
    }) 
  }
}
