
import { ApiResource, AssociationResource, MaintenanceItemPrototypeResource, MaintenanceItemTypeResource, MaintenanceSystemsResource, PropertyMaintenanceItemResource, PropertyMaintenanceSystemsResource, PropertyResource } from "@/resources";
import { uniq } from "lodash-es";
import AsyncComputed from "vue-async-computed-decorator";
import { Component, Prop, Vue} from "vue-property-decorator";
import PropertyItemAdd from "@/util/propertyItemAdd"
import PropertyMaintenanceItemSystemSelector from "@/components/properties/PropertyMaintenanceItemSystemSelector.vue"

/**
 * Mode to search/add for parts to add to existing property maintenance equipment
 */
export const MODE_ADD_PARTS_TO_EQUIPMENT = 0

/**
 * Mode to search/add specific part type (missing) to equipment
 */
 export const MODE_ADD_MISSING_PART_TO_EQUIPMENT = 1

 /**
 * Mode to search/add specific part type (missing) to equipment
 */
 export const MODE_ADD_PARTS_TO_SYSTEM = 2


@Component({components : {PropertyMaintenanceItemSystemSelector}})
export default class PropertyMaintenanceItemAddPanel extends Vue {

  @Prop({default: false}) multiple !: boolean 
  @Prop({required: true, default: -1}) mode !: 0 | 1 | 2

  @Prop({required: true}) propertyResource !: PropertyResource
  @Prop() itemParent !: PropertyMaintenanceItemResource
  @Prop() itemPrototype !: MaintenanceItemPrototypeResource

  /**
   * When not adding to equipment, the system to use to fetch the rolled up list
   * of available types to search from, also limits available subtrees when
   * disambiguating to only those relevant to this system.
   */
  @Prop() pmsSearchContext !: PropertyMaintenanceSystemsResource

  adding : boolean = false

  errorMsg : string | any[] = ""
  addError : string = ""

  search : string = ""
  searching : boolean = false
  searchResults : any = []

  apiSearchByType : AssociationResource<MaintenanceItemPrototypeResource[]> = new MaintenanceItemPrototypeResource().searchByType
  apiPmsFindPrimary : AssociationResource<PropertyMaintenanceSystemsResource> = new PropertyMaintenanceSystemsResource().searchPrimarySystem

  items : any [] = []

  treeMenu : boolean = false
  treeMenu_x : number = 0
  treeMenu_y : number = 0
  selectedItem : any = null

  /**
   * Used to determine if the system of the prototype is ambiguous and
   * needs user input
   * TODO overlap with SystemSelectorField
   */
  loadingTree : boolean = false
  allPms : PropertyMaintenanceSystemsResource[] = []
  allMs : MaintenanceSystemsResource[] = []
  treeKeyIdx : number = 1

  get title() {
    switch (this.mode) {
      case MODE_ADD_PARTS_TO_EQUIPMENT : 
        return "Add parts to equipment";
      case MODE_ADD_MISSING_PART_TO_EQUIPMENT:
        return "Add missing part";
      case MODE_ADD_PARTS_TO_SYSTEM:
        return "Add equipment"
    }
    return "Add item"
  }

  created() {
    try {
      // adding to property equipment, requires parent and parent must be equipment
      if (this.mode == MODE_ADD_PARTS_TO_EQUIPMENT || this.mode == MODE_ADD_MISSING_PART_TO_EQUIPMENT) {
        if (!this.itemParent) { throw new Error("Missing parent equipment.")}
        if (!this.itemParent.data().prototype.isEquipment) {throw new Error("Parent must be equipment.")}
        if (this.mode == MODE_ADD_MISSING_PART_TO_EQUIPMENT && !this.itemPrototype) {throw new Error("Missing item prototype for unknown part.")}
      }
      // adding to prototype equipment, requires parent and parent must be equipment
      else if (this.mode == MODE_ADD_PARTS_TO_SYSTEM) {
        if (!this.pmsSearchContext) { throw new Error("Missing maintenance system search context.")}
      }
      else {
        throw new Error("Invalid mode: " + this.mode)
      }
    }
    catch (e : any) {
      this.errorMsg = "Component error: " + e.message
    }

    // load all PMS and MS for use in determining system ambiguity
    this.loadingTree = true
    this.prepTreeRequirements().finally(() => {
      this.loadingTree = false
    })
  }

  showTreeMenu(item: any, evt : any) {
    this.selectedItem = item
    this.treeMenu = false
    evt.preventDefault()
    this.treeMenu_x = evt.clientX
    this.treeMenu_y = evt.clientY
    this.$nextTick(() => {
      this.treeMenu = true
    })
  }

  async prepTreeRequirements() {
    this.allPms.splice(0)
    let pmsFetch = await this.propertyResource.maintenanceSystems.getAssociation({projection: 'propertyMaintenanceSystemSummary'}, false)
    this.allPms.push(...pmsFetch)

    this.allMs.splice(0)
    let msFetch = await ApiResource.Instance.maintenanceSystems.getAssociation({}, false)
    this.allMs.push(...msFetch)
  }

  addItem(item : any) {

    let itemRequest = new PropertyItemAdd()
    itemRequest.prototype
    itemRequest.property = this.propertyResource.uriFull
    itemRequest.prototype = item.uri
    
    let itemCopy = {...item, isAmbiguous : true, itemRequest : itemRequest}

    // if adding part to equipment, then we use the PMS of the equipment, no tree needed,
    // else we need to do this tree build and ambiguity check
    if (this.mode == MODE_ADD_MISSING_PART_TO_EQUIPMENT
    ||  this.mode == MODE_ADD_PARTS_TO_EQUIPMENT) {
      itemCopy.isAmbiguous = false
      itemRequest.parentEquipment = this.itemParent.uriFull
    }
    else {
      itemCopy.systemTree = this.buildSystemSelectorTree(itemCopy)
    }

    this.items.push(itemCopy)
    this.items.sort(this.itemComparator)
  }

  removeItem(item : any) {
    this.items.splice(this.items.findIndex(i => i.id == item.id), 1)
  }

  isResolved(item: any) {
    let ir = item.itemRequest
    
    // resolved if we have a pms, or an MS with a parent ms
    return (ir.propertyMaintenanceSystem || (ir.maintenanceSystem && ir.parentOrGrandparentPropertyMaintenanceSystem))
  }

  async doAdd() {
    this.adding = true

    let itemRequests = this.items.map((i:any) => i.itemRequest)
    await new PropertyResource("addItems").postNoFollow(itemRequests)
      .catch(e => {
        console.error(e)
        this.addError = "Server error : " + e?.status
      })
      .finally(() => {
        this.adding = false
        this.$emit('close', true)
      })
  }

  get numIssues() {
    let iCount = 0
    for (let i of this.items) {
      if (i.isAmbiguous && !this.isResolved(i)) {
        iCount++
      }
    }
    return iCount
  }

  /**
   * No issues if all ambiguous items have been resolved
   */
  get noIssues() {
    return this.numIssues == 0
  }

  async getTypeIds() : Promise<number[]> {
    var typeIds = []

    // adding part to prototype equipment, restrict types by allowed part types
    if (this.mode == MODE_ADD_PARTS_TO_EQUIPMENT) {
      // TODO would be helpful to have this prop typed to a particular projection, would save having
      // to traverse a few things
      await this.itemParent.get()
      let parentPrototype = await this.itemParent.prototype.getAssociation()

      // we want type of prototype, and then the type parts, not the type of it's prototype parts
      //let itemTypePartResources = await parentPrototype.parts.getAssociation()
      let ppType = await parentPrototype.maintenanceItemType.getAssociation()
      let ppTypePartsResources = await ppType.parts.getAssociation()
      
      typeIds = ppTypePartsResources.map((r) => r.data().id)
    }
    // adding specific SINGLE missing part to equipment, restrict to type of missing part prototype
    else if (this.mode == MODE_ADD_MISSING_PART_TO_EQUIPMENT) {
      await this.itemPrototype.get()

      let itemTypePartResource = new MaintenanceItemTypeResource(this.itemPrototype.data().typeId)
      await itemTypePartResource.get()
      typeIds = [itemTypePartResource.data().id]
    }
    // If adding part/equipment to particular system, fetch rolled up types for system
    else if (this.mode == MODE_ADD_PARTS_TO_SYSTEM) {
      await this.pmsSearchContext.get()
      let ms = await this.pmsSearchContext.maintenanceSystem.getAssociation()
      let itemTypePartResources = await ms.maintenanceItemTypesRolledUp.getAssociation()
      typeIds = itemTypePartResources.map((r) => r.data().id)
    }

    return uniq(typeIds)
  }


  get filteredSearchResults() {

    let filtered = this.searchResults
    if (this.search.trim()) {
        filtered = this.searchResults.filter((i:any) => {
        return i.systemsName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
              i.typeName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
              i.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    }

     // clear previous results
    // group by system name
    let grouped : any = {}
    filtered.map((i:any) => {
      if (!grouped[i.systemsName]) {
        grouped[i.systemsName] = []
      }
      grouped[i.systemsName].push(i)
    })

    return grouped
  }

  @AsyncComputed() // FOR DEBOUCE
  async doSearch() {
    this.searching = true

    // Get type ids of item type parts
    var typeIds = await this.getTypeIds();
    // @ts-ignore
    var searchResources = await this.apiSearchByType.getAssociation({typeIds: typeIds, projection: "maintenanceItemPrototypeDetail"}, false)
   
    // if not adding a prototype, we expand results for those with multiple systems
    // TODO support for use in prototype admin
    let expandSystems = true
    this.searchResults.splice(0)
    this.searchResults.push(...this.mapResourcesToResults(searchResources, expandSystems))
    this.searchResults.sort(this.itemComparator)

    // if adding at system level, don't show parts, only equipment
    if (this.mode == MODE_ADD_PARTS_TO_SYSTEM) {
      this.searchResults = this.searchResults.filter((s:any) => s.isEquipment)
    }
    
    this.searching = false
  }

  getSystems(itemType : any) {
    var systems = itemType.maintenanceSystems.map((ms : any) => ms.name)
    return systems.join(" / ")
  }

  itemComparator(a: any, b: any) {
    let sidx = a.systemsName.localeCompare(b.systemsName)
    if (sidx != 0) {
      return sidx
    }
    let tidx = a.typeName.localeCompare(b.typeName)
    if (tidx != 0){
      return tidx
    }
    return a.name.localeCompare(b.name)
  }

  mapResourceToResult(r : MaintenanceItemPrototypeResource, idx : number) {
    return {
        id : idx,
        name : r.data().name,
        isEquipment : r.data().isEquipment,
        hasSystems : r.data().maintenanceSystems,
        systemsName : this.getSystems(r.data()),
        msid : "",
        msURI : "",
        typeName : r.data().typeName,
        uri : r.uriFull,
      }
  }

  mapResourcesToResults(resources : MaintenanceItemPrototypeResource[], expand : boolean) {
    let results : any[] = []
    if (!expand) {
      results = resources.map((r, idx) => this.mapResourceToResult(r, idx))
    } 
    // map each resource, but expand for multiple systems
    else {
      resources.forEach((r) => {
        if (r.data().maintenanceSystems && r.data().maintenanceSystems.length > 1) {
          r.data().maintenanceSystems.forEach((ms:any) => {
            let result = this.mapResourceToResult(r, results.length)
            result.msid = ms.id
            result.msURI = new MaintenanceSystemsResource(ms.id).uriFull
            result.systemsName = ms.name

            results.push(result)
          })
        } else {
          let result = this.mapResourceToResult(r, results.length)
          // TODO if not expanding, use first ?  
          result.msid = r.data().maintenanceSystems[0].id
          result.msURI = new MaintenanceSystemsResource(result.msid).uriFull
          results.push(result)
        }
      })
    }
    return results
  }


  /*************************/
  buildSystemSelectorTree(item: any) {
    let msURI = item.msURI
    
    this.treeKeyIdx = 1
    item.parentMap = []

    // create nodes for the ms tree
    let msTree : any[] = this.allMs.map((r) => ({
      uri : r.uriFull,
      name : r.data().name,
      levelType : r.data().levelType,
      msid : r.data().id,
      disabled : msURI && msURI != r.uriFull,
      parent_msid : r.data().parentSystemId,
      parent_node : null,
      children : [],
    }))
    // wire up ms children
    msTree.forEach((r:any) => {
      r.key = this.treeKeyIdx++

      let parentNode = msTree.find(node => r.parent_msid == node.msid)
      if (parentNode) {
        item.parentMap[r.key] = parentNode
        parentNode.children.push(r)
      }
    })

    // create pms tree and wire up children
    let pmsTree : any[] = this.allPms.map((r) => ({
      uri : r.uriFull,
      name : r.data().maintenanceSystemName,
      location : r.data().location,
      levelType : r.data().levelType,
      msid : r.data().maintenanceSystemId,
      pmsid : r.data().id,
      parent_pmsid : r.data().parentSystemId,
      parent_node : null,
      children : []
    }))
    // wire up ms children
    pmsTree.forEach((r:any) => {
      r.key = this.treeKeyIdx++

      let parentNode = pmsTree.find(node => r.parent_pmsid == node.pmsid)
      if (parentNode) {
        item.parentMap[r.key] = parentNode
        parentNode.children.push(r)
      }
    })

    // TODO overlap with SystemSelectorField
    // wire in pms
    let msRoot = msTree.find(t => t.levelType == "ROOT")
    let pmsRoot = pmsTree.find(t => t.levelType == "ROOT")

    let fullTree = this.buildTree(item, msRoot, pmsRoot);

    // trim to search context
    if (this.pmsSearchContext) {

      // locate the search context node
      let search_pmsid = this.pmsSearchContext.data().id
      let locator : Function
      locator = (node : any) : any => {
        if (node.pmsid === search_pmsid) return node
        for (let c of node.children) {
          let found = locator(c)
          if (found) return found
        }
        return false
      }
      let searchNode = locator(fullTree)

      // if found, work up the tree removing siblings of the same type
      // that aren't related to this node
      if (searchNode) {
        while (searchNode.parent_msid != null) {
          let msid = searchNode.msid
          let pmsid = searchNode.pmsid
          let parent = item.parentMap[searchNode.key]

          let otherKids = parent.children.filter((c:any) => c.msid != msid || (c.msid == msid && c.pmsid == pmsid)) 
          parent.children = otherKids

          searchNode = parent
        }
      }


      // let trimmer : Function
      // // locate subtree containing search context system
      // // and remove any parent siblings (not of that subtree)
      // trimmer = (node : any) => {
      //   for (let c of node.children) {

      //   }
      // }
    }

    // using the item msURL, see if it only corresponds to a single
    // MS node in the tree, if true, then we can auto select it and
    // disable the tree
    let msCount : any[] = []
    let seeker : Function
    seeker = (node : any) => {
      if (!node.disabled) {
        msCount.push(node)
      }
      node.children.forEach((c:any) => seeker(c))
    }
    seeker(fullTree)

    // if just one, auto select it and
    // disable the control
    if (msCount.length == 1) {
      this.populateItemRequest(item, msCount)
      item.isAmbiguous = false
    }
    // else there is more than one, prune the tree to make
    // it easier
    else {
      let pruner : Function

      pruner = (node : any) => {
        // remove any children that do not have a matching node
        let kids : any[] = [...node.children]
        kids.forEach(c => {
          let found = pruner(c)
          if (!found) {
            var cidx = node.children.findIndex((nc:any) => nc.key === c.key)
            if (cidx != -1) {
              node.children.splice(cidx, 1)
            }
          }
        })
        // if we found at least one matching child, or this node is
        // matching, we need to keep this node
        var foundOneChild = node.children.length > 0
        return foundOneChild || (!node.disabled)
      }
      pruner(fullTree)

      // ensure everything is deselected when redoing tree
      this.populateItemRequest(item, [])
    }

    return fullTree.children
  }

  populateItemRequest(item: any, values : any[]) {

    //this.activeItems = values
    if (values.length > 0) {
      let obj = values[0]
      let uri = obj.uri

      // is this an ms ?
      if (uri.indexOf(MaintenanceSystemsResource.prototype.name) > -1) {
        item.itemRequest.maintenanceSystem = uri
        item.itemRequest.propertyMaintenanceSystem = null

        // find pms parent (may not be direct parent, and may be none if new)
        let node = item.parentMap[obj.key]
        let parentPms = null
        while (node && !parentPms) {
          if (node.uri.indexOf(PropertyMaintenanceSystemsResource.prototype.name) > -1) {
            parentPms = node
          }
          else {
            node = item.parentMap[node.key]
          }
        }

        if (parentPms) {
          item.itemRequest.parentOrGrandparentPropertyMaintenanceSystem = parentPms.uri
        }
        else {
          item.itemRequest.parentOrGrandparentPropertyMaintenanceSystem = null
        }

      }
      // or a pms ?
      else if (uri.indexOf(PropertyMaintenanceSystemsResource.prototype.name) > -1) {
        item.itemRequest.propertyMaintenanceSystem = uri
        item.itemRequest.maintenanceSystem = null
        item.itemRequest.parentOrGrandparentPropertyMaintenanceSystem = null
      }
    }
    else {
      // no selection/deselection
      item.itemRequest.propertyMaintenanceSystem = null
      item.itemRequest.maintenanceSystem = null
      item.itemRequest.parentOrGrandparentPropertyMaintenanceSystem = null
    }
  }


  buildTree(item: any, msNode : any, pmsNode : any) {
    let newNode : any

    // if no pms node, clone and depth first children
    if (!pmsNode) {
      newNode = {...msNode, children : []}

      // ensure unique key, may be more than
      // on ms node of the same type in the structure
      newNode.key = this.treeKeyIdx++

      msNode.children.forEach((c:any) => {
        let newChild = this.buildTree(item, c, null)
        item.parentMap[newChild.key] = newNode

        newNode.children.push(newChild)
      })
    }
    // else merge, clone and depth first children
    else {
      newNode = {...msNode, ...pmsNode, children : []}

      // note because we are merging with pms node, we know
      // it will have a unique key

      msNode.children.forEach((mc:any) => {

        // find pms children of same type (may be more than one)
        let pcs = pmsNode.children.filter((pc:any) => mc.msid == pc.msid)
        
        // no kids, clone ms tree
        if (pcs.length == 0) {
          let newChild = this.buildTree(item, mc, null)
          item.parentMap[newChild.key] = newNode

          newNode.children.push(newChild)
        }
        else {
          pcs.forEach((pc : any) => {
            let newChild = this.buildTree(item, mc, pc)
            item.parentMap[newChild.key] = newNode

            newNode.children.push(this.buildTree(item, mc, pc))
          })
        }
      })
    }

    return newNode
  }

}
