
import {Component, Vue, Watch} from 'vue-property-decorator'
import { Debounce } from 'vue-debounce-decorator'
import AsyncComputed from 'vue-async-computed-decorator'
import { ClientResource, PropertyResource } from '@/resources'

@Component({components: {}})
export default class PropertyClientAddClientSearch extends Vue {

  search : string = ""
  clientApi : ClientResource = new ClientResource()
  selectedClientIdx : any = null
  clientResults : any = []

  @Watch("selectedClientIdx")
  selectedClientIdxChanged() {
    if (this.selectedClientIdx !== null && this.selectedClientIdx >= 0) {
      this.$emit("existingClient", this.clientResults[this.selectedClientIdx])
    }
    else {
      this.$emit("existingClient", null)
      
    }
  }
  
  @AsyncComputed({shouldUpdate() {return false}})
  async clients () {
    if (!this.search) {
      this.clientResults.splice(0)
      this.$emit("existingClient", null)
      this.selectedClientIdx = null
      return
    }
    this.clientResults = await this.clientApi.searchByNameOrAddress.getAssociation({nameOrAddress: this.search, projection: "clientNamesOnlyInlineProperties"})
    return this.clientResults
  }

  getPropertyAddressFromClient(client : any) {
    // TODO assumes one prop
    return this.propertyUtil.getPropertyAddress(client.data().properties[0])
  }


  get clientUtil() : any {
    return ClientResource
  }

  get propertyUtil() : any {
    return PropertyResource
  }

  @Watch('search')
  @Debounce(300)
  onSearchChanged() {
    this.$asyncComputed.clients.update()
  }

}
