
import { MaintenanceItemTypeSchemaResource } from "@/resources"
import { Vue, Component, Prop } from "vue-property-decorator"

@Component({components : {}})
export default class JsonModelDisplayComponent extends Vue {

  @Prop({required: true}) readonly jsonSchema !: any
  @Prop({required: true}) readonly model !: any
  
  /**
   * Returns the header array to be used for displaying a preset.
   * @param pName 
   */
   isPresetProperty(pName : string) {
    return MaintenanceItemTypeSchemaResource.isPresetProperty(pName, this.jsonSchema)
  }

  fieldTitle(pName : string) {
    return this.jsonSchema.properties[pName].title
  }


  hasSomeValue(pName : string) {
    if (this.isPresetProperty(pName)) {
      return this.presetNotEmpty(pName)
    }
    return this.nonPresetNotEmpty(pName)
  }

  nonPresetNotEmpty(pName : string) {
    return this.model[pName] && this.model[pName].trim()
  }

  /**
   * Returns true if preset values have some data in them
   * @param pName 
   */
  presetNotEmpty(pName : string) {
    var values = this.presetValues(pName).pop()
    for (const val of Object.values(values)) {
      if (val != undefined && ("" + val).trim()) {
        return true
      }
    }
    return false
  }

  /**
   * Returns an arrary of a single object to be used for displaying a preset.
   * @param pName 
   */
   presetValues(pName : string) {
    var fields = MaintenanceItemTypeSchemaResource.getPresetPropertyFields(pName, this.jsonSchema)
    const values : any = {}
    Object.keys(fields).forEach(fName => {
      values[fName] = this.model[fName]
    })
    return [values]
  }

  /**
   * Returns the header array to be used for displaying a preset.
   * @param pName 
   */
  presetHeaders(pName : string) {
    var fields = MaintenanceItemTypeSchemaResource.getPresetPropertyFields(pName, this.jsonSchema)
    const headers : any = []
    Object.entries(fields).forEach(entry => {
      const [fName, fSettings] : [string,any] = entry;
      
      // only add header if there is a preset value
      if (this.nonPresetNotEmpty(fName)) {
        headers.push({text: fSettings.title, value: fName, class: "pl-0", cellClass: "pl-0"})
      }
    })
    return headers
  }

  /**
   * Returns the list of property names with any properties which are part of a preset removed.  The name
   * property is pushed to the top of the list.
   */
  get props() : any {
    var props = ['name']

    for (const pName of Object.keys(this.jsonSchema.properties)) {
      if (pName != 'name' 
      && !MaintenanceItemTypeSchemaResource.isPresetFieldProperty(pName, this.jsonSchema)
      && this.hasSomeValue(pName)) {
        props.push(pName)
      }
    }
    return props
  }

}
