
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import { Loader } from "@googlemaps/js-api-loader"
import { AppointmentResource } from '@/resources'
import { DateTime } from 'luxon'

@Component({components: {}})
export default class AppointmentsMap extends Vue {
    @Prop({}) readonly appointments !: any[]
    @Prop({ default: true }) readonly mapTypeControl!: boolean
    @Prop({ default: true }) readonly scaleControl!: boolean
    @Prop({ default: true }) readonly zoomControl!: boolean
    @Prop({ default: true }) readonly panControl!: boolean
    @Prop({ default: false}) readonly streetViewControl!: boolean
    @Prop({ default: true }) readonly fullscreenControl!: boolean
    @Prop() readonly selectedAppointment!: AppointmentResource

    map !: google.maps.Map 
    apiKey : any = process.env.VUE_APP_GOOGLE_API_KEY
    markers = new Map<any, google.maps.Marker>()

    $refs!: {
      map: HTMLElement
    }

    @Watch("selectedAppointment")
    selectedAppointmentChanged(newVal : any, oldVal : any) {
      if (newVal) {
        const marker = this.markers.get(newVal.data().id)
        if (marker) {
          marker.setIcon(this.getMarkerIcon(newVal.data(), true))
        }
      }
      if (oldVal) {
        const marker = this.markers.get(oldVal.data().id)
        if (marker) {
          marker.setIcon(this.getMarkerIcon(oldVal.data(), false))
        }
      }
    }

    beforeDestroy() {
      if (this.map) {
        google.maps.event.clearInstanceListeners(this.map);
      }
    }

    setMapBounds(viewportBounds : any) {
      // use viewport if we have it
      if (viewportBounds) {
        var points = viewportBounds.split('|')
        if (points.length === 4) {
          var boundsA = new google.maps.LatLng(points[0],points[1])
          var boundsB = new google.maps.LatLng(points[2],points[3])
          var bounds = new google.maps.LatLngBounds()
          bounds.extend(boundsA)
          bounds.extend(boundsB)
          this.map.fitBounds(bounds)
        }
      }
    }

    getMarkerIcon(appointment : any, highlight : boolean = false) {
      const backgroundColor = this.getBackgroundColor(appointment.status)
      return {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: backgroundColor,
          fillOpacity: 0.6,
          strokeWeight: highlight ? 4 : 0,
          scale: 15,
        }
    }

    drawAppointment(map : google.maps.Map, appointment : any, label: string, idx: number) {
      if (!appointment.jobs) return

      var property = appointment.jobs[0].property
      var geo = property.geo
      if (!geo) {
        geo = this.centerCoordinates
      } 
      
      var foregroundColor = this.getForegroundColor(appointment.status)
      
      this.markers.set(appointment.id, new google.maps.Marker({
        map: map,
        position: { lat: geo.latitude, lng: geo.longitude },
        title: label,
        icon: this.getMarkerIcon(appointment),
        label: {color: foregroundColor, fontSize: '11px', fontWeight: '600', text: "" + idx}
      }))      
    }

    @Watch("appointments")
    appointmentsChanged() {
      if (this.map) {
        // clear all
        for (let marker of this.markers.values()) {
          marker.setMap(null)
        }
        this.markers.clear()

        if (this.appointments) {
          // ensure appts are in order
          [...this.appointments].sort((a : any, b : any) => {
            var adate = typeof a.start === 'number' ? DateTime.fromMillis(a.start) : DateTime.fromJSDate(a.start)
            var bdate = typeof b.start === 'number' ? DateTime.fromMillis(b.start) : DateTime.fromJSDate(b.start)
            return adate < bdate ? -1 : adate > bdate ? 1 : 0;
          })
          .forEach((a:any, idx:number) => this.drawAppointment(this.map, a.data, "appointment", idx+1))
        }
      }
    }

    getStatusColor(statusValue : string) {
      return AppointmentResource.getStatusColor(statusValue)
    }

    getBackgroundColor(statusValue : string) {
      const c = this.getStatusColor(statusValue)
      return c ? c.background : "blue"
    }

    getForegroundColor(statusValue : string) {
      const c = this.getStatusColor(statusValue)
      return c ? c.foreground : "#FFF"
    }

    get centerCoordinates() {
      // TODO set bounds on set of appts. 
      return {latitude : 49.1450161, longitude: -123.8566661}
    }

    mounted() {
        const loader = new Loader({
          apiKey: this.apiKey,
          version: "weekly",
        });
        this.$emit("updating")
        loader.load().then(() => {

          /* global google */
          // create bounds
          var center
          // TODO set bounds on set of appts. 
          const geo : any = this.centerCoordinates
          center = new google.maps.LatLng(geo.latitude,geo.longitude)
          
          var mapOptions = {
            center: center,
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControl: this.panControl,
            mapTypeControl: this.mapTypeControl,
            fullscreenControl : this.fullscreenControl,
            panControlOptions: {
                  position: google.maps.ControlPosition.RIGHT_CENTER
            },
            zoomControl: this.zoomControl,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: this.scaleControl,
            streetViewControl: this.streetViewControl,
            streetViewControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            }
          }
              
          // create map & marker
          this.map = new google.maps.Map(this.$refs.map, mapOptions)

          const styles: Record<string, google.maps.MapTypeStyle[]> = {
              default: [],
              hide: [
                {
                  featureType: "poi",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "transit",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }],
                },
              ],
            };

          this.map.setOptions({styles : styles['hide']})

          const countDiv = document.getElementById("map-p-count") as HTMLElement;
          this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(countDiv);

          // fire marker draw
          this.appointmentsChanged();

          this.map.addListener("bounds_changed", () => {
            this.$emit("updating")
          });
          this.map.addListener("idle", () => {
            this.$emit("updated")
          });

          // if one property, set bounds based on that.
          //if (this.size == 1 && this.resource) {
          //  this.setMapBounds(this.rdata.geo.viewportBounds);
          //}
        });
    }

    /*@Watch("rdata")
    geoChanged(newGeo : any) {
      const center = new google.maps.LatLng(newGeo.latitude,newGeo.longitude)
      this.marker.setPosition(center);
      this.map.setCenter(center);
      this.setMapBounds(newGeo.viewportBounds)
    }*/
}
