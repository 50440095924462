
import { ClientResource, EntityResource, MaintenanceJobResource, PropertyResource, SettingsResource } from "@/resources"
import _ from "lodash"
import { orderBy } from "lodash-es"
import { DateTime } from "luxon"
import { Vue, Component, Watch} from "vue-property-decorator"
import VirtualScroll from "vue-virtual-scroll-list"
import BasePanel from "../base/BasePanel.vue"
import AppointmentJobDetailsPanel from "./AppointmentJobDetailsPanel.vue"
import SchedulingPoolJob from "./SchedulingPoolJob.vue"
import UnscheduledJobActions from "@/components/actions/UnscheduledJobActions.vue"

@Component({name: "SchedulingPool", components : {UnscheduledJobActions, AppointmentJobDetailsPanel, SchedulingPoolJob, BasePanel, VirtualScroll}})
export default class SchedulingPool extends Vue {

  loading : boolean = false
  loadingMore : boolean = false
  resources : MaintenanceJobResource[] = []

  jobDialog : boolean = false
  selectedJob : MaintenanceJobResource | null = null

  showSearch : boolean = false
  searchStr : string = ''

  initialLoadSize : number = 60
  triggerRefresh : number = 1

  drawer : boolean = false
  hidePool : boolean = false

  actionsMenu : boolean = false
  actionsMenuX : number = 0
  actionsMenuY : number = 0
  actionsMenuJob : MaintenanceJobResource | null = null
  actionsMenuMultipleJobs : MaintenanceJobResource[] = []

  @Watch("showSearch")
  showSearchChanged(newVal : any, oldVal : any) {
    if (newVal === false && oldVal != undefined && newVal != oldVal) {
      this.searchStr = ""
    }
  }

  get containerHeight() {
    return 'height: calc(100vh - ' + (this.loadingMore ? 174 : 124) + 'px);'
  }

  /**
   * Returns the # of jobs in the pool (including those grouped).  If search is active,
   * only the # jobs in the search results is returned.
   */
  get jobCount() {
    if (!this.showSearch) {
      let jc = this.resources.length + (this.loadingMore  ? "+" : "")
      this.$emit('jobCount', jc)
      return jc
    }

    var count = 0
    this.sortedGroupedAndFiltered.forEach((r : any) => {
      if (r.multipleJobs.length > 0) {
        count = count + r.multipleJobs.length
      }
      else {
        count++
      }
    })
    this.$emit('jobCount', count)
    return count

  }

  entityUpdated(event : string) {
    const [type, uri] = event.split(": ")
    var parts = uri.split("/")
    var id = parts[parts.length-1] 

    // Appointment has been added/deleted
    // APPT-ADD: /api/jobs/10853
    if (type === "APPT-ADD" || type === "APPT-DEL") {

      const idx = this.resources.findIndex(r => r.data().id == id)
      // appt added, remove from this list
      if (type === "APPT-ADD") {
        if (idx >= 0) {
          this.resources.splice(idx, 1)
        }
      } 
      // appt deleted, re-add to this list if it doesn't already exist
      else {
        if (idx == -1) {
          var unscheduledJob = new MaintenanceJobResource(id + "?projection=maintenanceJobSummary");
          unscheduledJob.get(false).then((s) => {
            if (s.data.status == "OPEN_UNSCHEDULED") {
              this.resources.push(unscheduledJob)
            }
          })
        }
      }
    } 
    // bump check
    else if (type === "UPDATE" && uri.indexOf("/api/jobs") != -1) {
      const idx = this.resources.findIndex(r => r.data().id == id)
      if (idx >= 0) {
        var r = this.resources[idx]
        // force reload and touch the collection to get the list
        // to show any new order
        r.get(false).then(() => {
          this.resources.splice(idx, 1, r)
        })
      }
    }
    // delete check
    else if (type === "DELETE" && uri.indexOf("/api/jobs") != -1) {
      const idx = this.resources.findIndex(r => r.data().id == id)
      if (idx >= 0) {
          this.resources.splice(idx, 1)
      }
    }
    // new job added
    else if (type === "ADD" && uri.indexOf("/api/jobs") != -1) {
      // only add if no appointments (unscheduled)
      let newJob = new MaintenanceJobResource(id + "?projection=maintenanceJobSummary")
      newJob.get().then(s => {
        if (s.data.status === MaintenanceJobResource.STATUS_OPEN_UNSCHEDULED) {
          this.resources.push(newJob)
        }
      })
    }
  }

  beforeMount() { this.$eventBus.on('entityUpdate', this.entityUpdated)}
  beforeUnmount() { this.$eventBus.off('entityUpdate', this.entityUpdated)} // vue3
  beforeDestroy() {  this.$eventBus.off('entityUpdate', this.entityUpdated)} // vue2

  selectContext(evt : any) {
    this.actionsMenuX = evt.event.clientX
    this.actionsMenuY = evt.event.clientY
    this.actionsMenuJob = evt.resource
    this.actionsMenuMultipleJobs = evt.multipleJobs
    this.actionsMenu = true
  }

  selectJob(job : MaintenanceJobResource) {
    this.selectedJob = job;
    this.jobDialog = true;
  }

  get jobDialogTitle() {
    if (!this.selectedJob) return ""
    let job = this.selectedJob.data()
    return PropertyResource.getPropertyAddress(job.property) + " :: " + ClientResource.getClientName(job.property.client)
  }

  resourceId(resource : any) {
    return resource.resource.data().id
  }

  created() {
    this.fetch()
  }

  get clientUtil() {
    return ClientResource
  }

  get jobGroupingInDays() {
    return SettingsResource.defaultSetting(SettingsResource.SETTING_JOB_GROUPING_DAYS)
  }

  get sortedGroupedAndFiltered() {
    if (this.searchStr && this.searchStr.trim()) {
      return this.sortedAndGroupedResources.filter((r:any) => {
        var address = r.resource.data().property?.address?.address
        var client = r.resource.data().property.client
        var clientName = undefined

        if (client && client.contacts && client.contacts.length) {
          clientName = this.clientUtil.getClientName(client)
        }

        return (address && address.toLowerCase().indexOf(this.searchStr.toLowerCase()) > -1) 
            || (clientName && clientName.toLowerCase().indexOf(this.searchStr.toLowerCase()) > -1)
      })
    } else {
      return this.sortedAndGroupedResources
    }
  }

  get sortedAndGroupedResources() {

    let sorted : any = orderBy(this.resources, (value : EntityResource) => _.get(value.data(), "startDate"), ['asc']).map(r => ({resource : r, multipleJobs : [], hasGroup: false, isGrouped: false}))

    sorted.forEach((r:any) => {
      let jid = r.resource.data().id
      let pid = r.resource.data().property.id
      let startDate = DateTime.fromISO(r.resource.data().startDate)

      // skip jobs already in a group
      if (!r.isGrouped) {
        // find properties in other jobs within our given time frame that
        // are not in a group, or have a grouping of jobs
        let groupedJobs = sorted
                  // find jobs & the same property not already a group, or in a group
                  .filter((r:any) => r.resource.data().id != jid && !r.isGrouped && !r.hasGroup && r.resource.data().property.id == pid)
                  // find other jobs between start date and X + start date
                  .filter((r:any) => 
                    DateTime.fromISO(r.resource.data().startDate) >= startDate &&
                    DateTime.fromISO(r.resource.data().startDate) <= startDate.plus({days:this.jobGroupingInDays}))
        groupedJobs.forEach((r:any) => r.isGrouped = true)

        if (groupedJobs.length > 0) {
          // add ourselves and the others                   
          r.multipleJobs = [r.resource, ...groupedJobs.map((r:any) => r.resource)]
          r.hasGroup = true
        }
      }
    })
    sorted = sorted.filter((r:any) => !r.isGrouped)

    return sorted
  }

  fetch() {
    var linkVariables = {
      projection: 'maintenanceJobSummary', 
      statuses : 'OPEN_UNSCHEDULED',
      page: 0,
      size: this.initialLoadSize} 
    
    this.loading = true
    this.loadingMore = true
    var total = 0
    this.initialBatchAssociationResource.getAssociation(linkVariables, false).then((results) => {

      // append results
      this.resources.push(...results)
      total = this.initialBatchAssociationResource.data().page.totalElements
      
      // don't load more if we have them all
      if (results.length <= total) {
        var moreLinkVariables = {...linkVariables, offset: results.length, limit: total-results.length}
        this.moreBatchAssociationResource.getAssociation(moreLinkVariables, false).then((results2) => {
          this.resources.push(...results2)
        }).
        finally(() => this.loadingMore = false)

      } else {
        this.loadingMore = true
      }

      
    })
    .finally(() => this.loading = false)
  }

  /**
   * We use two endpoints, the first will return a total as it is a
   * paged resource
   */
  get initialBatchAssociationResource() {
    return new MaintenanceJobResource().all
  }
  /**
   * This resource accepts limit and offset and is not paged.
   */
  get moreBatchAssociationResource() {
    return new MaintenanceJobResource().allLimitOffset
  }
 
}
