
import {Component, Prop} from 'vue-property-decorator' 
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import BaseHtmlEditor from "@/components/base/BaseHtmlEditor.vue"
import {AssociationResource, HealthReportResource, SystemMediaResource} from "@/resources"
import HealthStatusChip from './HealthStatusChip.vue'
import MediaImageStrip from '../media/MediaImageStrip.vue'

@Component({components: {BaseHtmlEditor, HealthStatusChip, MediaImageStrip}})
export default class HealthReportForm extends ValueBasedFormsMixin { 

  @Prop({default: false}) showCascade !: boolean
  @Prop({default: null}) mediaAssociationResource !: AssociationResource<SystemMediaResource[]>

  getHalFormName() : string { return "healthreport" }
  getColor(status : string) {
    return HealthReportResource.colorForStatus(status)
  }

  get mediaUploadUrl() {
    return process.env.VUE_APP_API_BASE_URL + '/systemMedias/upload'
  }
  
}
