
import {Component, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import { BaseAppointmentComponent } from '../base/BaseMixins'
import AppointmentEditForm from './AppointmentEditForm.vue'
import { MaintenanceJobResource, SettingsResource } from '@/resources'
import { DateTime } from 'luxon'

@Component({components: {BaseResourceFormDialog, AppointmentEditForm}})
export default class AppointmentEditDialog extends Mixins(ResourceFormDialogMixin, BaseAppointmentComponent) { 
  @Prop() maintenanceJobResource !: MaintenanceJobResource
  @Prop({default: false}) hideDateTime !: boolean
  @Prop({default: false}) showNotes !: boolean

  newModelFunction() : any {

    if (this.rdata) {
      return {...this.rdata, jobs : undefined}
    }
    if (!this.maintenanceJobResource) {
      throw new Error("maintenanceJobResource required for new appointment edit dialog")
    }
    const startDate = this.maintenanceJobResource.data().startDate
    const estimatedDuration = this.maintenanceJobResource.data().estimatedDuration

    // ensure end date is n GMT
    const model = {...SettingsResource.defaultObject("appointment"), 
              jobs : [this.maintenanceJobResource.uriFull],
              startDate: startDate,
              endDate :  DateTime.fromISO(startDate).plus({ hours: estimatedDuration ? estimatedDuration : 1}).setZone('utc').toISO()}
    return model
   }

}
