
import {Component, Vue} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import ClientNewForm from '../clients/ClientNewForm.vue'
import PropertyClientAddClientSearch from '@/components/properties/PropertyClientAddClientSearch.vue'
import { ApiResource, ClientResource, ContactResource, EntityResource, SettingsResource } from '@/resources'

@Component({components: {ClientNewForm, BaseDialog, PropertyClientAddClientSearch}})
export default class ClientAddDialog extends Vue { 

  data : any = {}
  step : number = 1
  existingClient : ClientResource | null = null
  apiResource : ApiResource = ApiResource.Instance
  adding : boolean = false
  addError : any = null

  setExistingClient(c : ClientResource | null) {
    this.existingClient = c
    this.step = 1
  }

  created() {
    this.step = 1
    this.$set(this.data, 'client', {...SettingsResource.defaultObject("client")})
    this.$set(this.data.client, 'contacts', [])
  }


  async deleteSilent(resources : EntityResource | EntityResource[]) {
    var toDel = Array.isArray(resources) ? resources : [resources]
    for (let er of toDel) {
      await er.deleteSilent()
    }
  }

  gotoClientAndClose(cid : any) {
    // close up shop
    this.$emit('input', false)

    // goto new client
    if (cid) {
      this.$router.push({name : 'client', params : {cid: cid}})
    }
  }

  async doTheDeed() {

    try {
      this.adding = true

      // create the client or use existing
      var contactResources = []
      var cid 

      if (!this.existingClient) {
        // create contacts
        for (let contact of this.data.client.contacts) {
          try {
            var contactState = await new ContactResource().post(contact)
          }
          catch (e:any) {
            // try to clean up any we've already created
            await this.deleteSilent(contactResources)
            throw new Error("Error creating client contacts: " + e?.status)
          }

          contactResources.push(new ContactResource(contactState.data.id))
        }

        // create client with contacts
        var newClient = {...this.data.client}
        newClient.contacts = contactResources.map(cr => cr.uriFull)
        try {
          var clientState = await new ClientResource().post(newClient)
          cid = clientState.data.id
        }
        catch (e:any) {
            // clean up contacts
            await this.deleteSilent(contactResources)
            throw new Error("Error creating client: " + e?.status)
        }
      } 

      this.gotoClientAndClose(cid)
    }
    catch (e : any) {
      this.addError = e.message ? e.message : "Could not add client, unknown error."
    }
    finally {
      this.adding = false
    }
  }
  

}
