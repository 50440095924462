
import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import { MaintenanceSystemsResource, PropertyMaintenanceSystemsResource } from '@/resources'

/**
 * TODO overlap with SystemSelectorField used when creating jobs
 */
@Component({components: {}})
export default class PropertyMaintenanceItemSystemSelector extends Vue {

  @Prop({required: true}) value !: any

  activeItems : any[] = []
  menu : boolean = false

  @Watch("value", {immediate: true})
  valueChanged() {
    if (this.value) {
      let selectedNode = this.findSelectedNode(this.value)
      if (selectedNode) {
        this.activeItems.push(selectedNode)
      }
    }
  }

  findSelectedNode(item : any) {
    var pmsUri = item.itemRequest.propertyMaintenanceSystem
    var msUri = item.itemRequest.maintenanceSystem
    var pgUri = item.itemRequest.parentOrGrandparentPropertyMaintenanceSystem

    if (pmsUri) {
      let node = this.findTreeNode(item.systemTree, pmsUri)
      if (node) return node
    }
    else if (msUri && pgUri) {
      let parentNode = this.findTreeNode(item.systemTree, pgUri)
      if (parentNode) {
        let node = this.findTreeNode(parentNode, msUri);
        if (node) return node
      }
    }
    return null
  }

  findTreeNode(node: any, uri : any) : any {

    if (Array.isArray(node)) {
      for (let n of node) {
        let found = this.findTreeNode(n, uri)
        if (found) return found
      }
      return null
    }

    if (node.uri === uri) {
      return node
    }
    for (let child of node.children) {
      let found = this.findTreeNode(child, uri)
      if (found) {
        return found
      }
    }
    return null
  }

  get active() {
    return this.activeItems
  }
  set active(values : any[]) {

    this.activeItems = values
    if (values.length > 0) {
      let obj = values[0]
      let uri = obj.uri

      // is this an ms ?
      if (uri.indexOf(MaintenanceSystemsResource.prototype.name) > -1) {
        this.$emit('maintenanceSystem', uri)
        this.$emit('propertyMaintenanceSystem', null)

        // find pms parent (may not be direct parent, and may be none if new)
        let node = this.value.parentMap[obj.key]
        let parentPms = null
        while (node && !parentPms) {
          if (node.uri.indexOf(PropertyMaintenanceSystemsResource.prototype.name) > -1) {
            parentPms = node
          }
          else {
            node = this.value.parentMap[node.key]
          }
        }

        if (parentPms) {
          this.$emit('parentOrGrandparentPropertyMaintenanceSystem', parentPms.uri)
        }
        else {
          this.$emit('parentOrGrandparentPropertyMaintenanceSystem', null)
        }

      }
      // or a pms ?
      else if (uri.indexOf(PropertyMaintenanceSystemsResource.prototype.name) > -1) {
        this.$emit('propertyMaintenanceSystem', uri)
        this.$emit('maintenanceSystem', null)
        this.$emit('parentOrGrandparentPropertyMaintenanceSystem', null)
      }
    }
    else {
      // no selection/deselection
      this.$emit('propertyMaintenanceSystem', null)
      this.$emit('maintenanceSystem', null)
      this.$emit('parentOrGrandparentPropertyMaintenanceSystem', null)
    }
  }


}
