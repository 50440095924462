
import { Drag } from 'vue-drag-drop';
import { DateTime } from "luxon";
import { Component, Prop} from "vue-property-decorator"
import { BaseMaintenanceJobComponent, RequiredProjections } from "../base/BaseMixins";
import ClientName from "../clients/ClientName.vue";
import PropertyAddress from "../properties/PropertyAddress.vue";
import BaseSchedulingEntry from "./BaseSchedulingEntry.vue";
import { MaintenanceJobResource } from '@/resources';

/**
 * https://colordesigner.io/gradient-generator
 * error: ff5252
 * warning: fb8c00
 * success: 4caf50
 * <= -7 #ff5252
 * <= -3 #fa6c4f
 * <= -1 #f6864d
 * <= 3 #f09f4b
 * <= 7 #e8c249
 * <= 14 #dbde48
 * <= 21 #add447
 * <= 24 #84c947
 * <= 27 #62bd49
 * <= 30 #4caf50
 */
const shadesOfTrouble = (daysOld : number) => {
  if (daysOld <= -7) return "#ff5252";
  if (daysOld <= -3) return "#fa6c4f";
  if (daysOld <= -1) return "#f6864d";
  if (daysOld <= 3) return "#f09f4b";
  if (daysOld <= 7) return "#e8c249";
  if (daysOld <= 14) return "#dbde48";
  if (daysOld <= 21) return "#add447";
  if (daysOld <= 24) return "#84c947";
  if (daysOld <= 27) return "#62bd49";
  return "#4caf50";
}

@RequiredProjections("maintenanceJobSummary")
@Component({name: "SchedulingPoolJob", components : {PropertyAddress, ClientName, BaseSchedulingEntry, Drag}})
export default class SchedulingPoolJob extends BaseMaintenanceJobComponent {

  @Prop({default: () => [] }) readonly multipleJobs !: MaintenanceJobResource[]
  @Prop({default: 220}) readonly width !: number

  showMoreJobs : boolean = false
  deleting : boolean = false

  get troubleColor() {
    if (this.rdata) {
      const startDate = DateTime.fromISO(this.rdata.startDate)
      const now = DateTime.now();
      const diff = startDate.diff(now, ["days"])
      
      let days = diff.toObject().days
      let daysInt = days ? Math.floor(days) : 0
      return shadesOfTrouble(daysInt)
    }
    return shadesOfTrouble(1000)
  }

  /**
   * Needed to stop the parent data from taking over from a child drag, see:
   * https://github.com/cameronhimself/vue-drag-drop/issues/20
   * @param data 
   * @param event 
   */
  handleChildDragstart(data : any, event : any) {
    event.stopPropagation();
  }

  getDateLong(dString : any) {
    return DateTime.fromISO(dString).toFormat('EEEE, MMMM d')
  }

  selectMain() {
    // if multiple jobs, expand, else fire select event for us
    if (this.multipleJobs.length) {
      this.showMoreJobs = !this.showMoreJobs
    }
    else {
      this.$emit("select", this.resource)
    }
  }

  contextMenuSelectMain(r : any, evt : any, multipleJobs : any) {
    //if (!this.multipleJobs.length) {
      this.$emit("selectContext", {resource : r, event : evt, multipleJobs : multipleJobs})
      //this.contextMenuX = evt.clientX
      //this.contextMenuY = evt.clientY
      //this.showContextMenu = true
    //}
  }

  /**
   * Make room for our badge number 
   */
  get textWidth() {
    return this.multipleJobs.length ? this.width-40 : this.width-20
  }

  get dateLong() {
    return this.getDateLong(this.rdata.startDate)
  }

  get dateShort() {
    // if year is not ours, show
    const startDate = DateTime.fromISO(this.rdata.startDate)
    const now = DateTime.now();

    const format = startDate.year === now.year ? 'MMMM d' : 'MMMM d, yyyy'
    return this.rdata ? DateTime.fromISO(this.rdata.startDate).toFormat(format) : ""
  }

  
}
