
import {Component, Prop} from 'vue-property-decorator' 
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import BaseHtmlEditor from "@/components/base/BaseHtmlEditor.vue"
import { AssociationResource, SystemMediaResource } from '@/resources'
import MediaImageStrip from '../media/MediaImageStrip.vue'

@Component({components: {BaseHtmlEditor, MediaImageStrip}})
export default class JobLogForm extends ValueBasedFormsMixin { 

  @Prop({default: null}) mediaAssociationResource !: AssociationResource<SystemMediaResource[]>

  getHalFormName() : string { return "maintenancelog" }

  get mediaUploadUrl() {
    return process.env.VUE_APP_API_BASE_URL + '/systemMedias/upload'
  }
  
}
