import { SettingsResource } from '@/resources'

/**
 * Utility class for atomically adding property/client and possibly job/appointment in one request.
 */
export default class PropertyComplexAdd {
    
    client : string | null = null
    property : string | null = null
    maintenanceSystem : string | null = null
    propertyMaintenanceSystem : string | null = null
    parentOrGrandparentPropertyMaintenanceSystem : string | null = null /** needed for when adding same PMS different parent */
    
    newProperty !: any
    newClient !: any
    newJob !: any
    newAppointment !: any

    public initClient(context : Vue) {
        context.$set(this, 'newClient', {...SettingsResource.defaultObject("client")})
        context.$set(this, 'newClient.contacts', [])
    }
    public initPropertyAndClient(context : Vue) {
        context.$set(this, 'newClient', {...SettingsResource.defaultObject("client")})
        context.$set(this, 'newClient.contacts', [])
        context.$set(this, 'newProperty', {...SettingsResource.defaultObject("property")})
    }
    public initJob(context : Vue) {
        context.$set(this, 'newJob', {...SettingsResource.defaultObject("maintenanceJob")})
    }
    public initJobAndAppointment(context : Vue) {
        context.$set(this, 'newJob', {...SettingsResource.defaultObject("maintenanceJob")})
        context.$set(this, 'newAppointment', {...SettingsResource.defaultObject("appointment")})
    }

}