
import {Component, Prop} from 'vue-property-decorator' 
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import BaseHtmlEditor from "@/components/base/BaseHtmlEditor.vue"
import AddressForm from '../address/AddressForm.vue'
import ContactTypeInfoCard from '../contacts/ContactTypeInfoCard.vue'
import { ContactResource, SettingsResource } from '@/resources'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue' 
import BaseDialog from '../base/BaseDialog.vue'
import ContactForm from '../contacts/ContactForm.vue'

@Component({components: {BaseOptionsMenu, BaseDialog, AddressForm, BaseHtmlEditor, ContactTypeInfoCard, ContactForm}})
export default class ClientNewForm extends ValueBasedFormsMixin { 

  @Prop() propertyAddress !: any
  showNotes : boolean = true
  selectedContact : any = null
  selectedContactIdx : number = -1
  contactDialog : boolean = false
  contactValid : boolean = false

  get excludeTypes() {
    return ['TENANT']
  }

  getHalFormName() : string { 
    return "client" 
  }

  get contactUtil() {
    return ContactResource
  }

  editContact(idx : number) {
    // make a copy
    this.selectedContactIdx = idx
    this.selectedContact = {...this.value.contacts[idx]}
    this.contactDialog = true
  }

  updateContact() {
    // if new, add contact, else replace
    if (this.selectedContactIdx == -1) {
      this.doUpdate('contacts', {...this.selectedContact, editedOnce : true}, true)
    }
    else {
      // special flag for contact form
      this.doUpdate('contacts[' + this.selectedContactIdx + ']', this.selectedContact)
    }
    this.contactDialog = false
  }

  deleteContact(idx : number) {
    this.doUpdate('contacts', this.selectedContact, true, idx)
    this.selectedContactIdx = -1
    this.selectedContact = null
  }

  addContact() {
    this.selectedContactIdx = -1
    this.selectedContact = {...SettingsResource.defaultObject("contact"), editedOnce : false}

    // set to secondary if we already have a primary
    if (this.value.contacts 
    && this.value.contacts.length > 0 
    && this.value.contacts[0].type == "PRIMARY") {
      this.selectedContact.type = "SECONDARY"
    }

    if (this.propertyAddress) {
      this.selectedContact.address = this.propertyAddress
    }
    this.contactDialog = true
  }
  
}
