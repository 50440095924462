
import {Component, InjectReactive, Mixins} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import JobLogForm from "./JobLogForm.vue"
import {ResourceFormDialogMixin, ResourceFormSystemMediaUploadMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, PropertyMaintenanceSystemsResource, MaintenanceJobResource, MaintenanceLogResource} from "@/resources"
import { BaseMaintenanceLogComponent } from '../base/BaseMixins'

@Component({components: {BaseResourceFormDialog, JobLogForm}})
export default class JobLogFormDialog extends Mixins(ResourceFormDialogMixin, BaseMaintenanceLogComponent, ResourceFormSystemMediaUploadMixin) { 

  @InjectReactive() readonly propertyMaintenanceSystem!: PropertyMaintenanceSystemsResource
  @InjectReactive() readonly maintenanceJobResource!: MaintenanceJobResource

  newModelFunction() : any {
    return this.rdata
            ? this.rdata
            : {...SettingsResource.defaultObject("maintenanceLog"), 
              job : this.maintenanceJobResource.resource.uri,
              propertyMaintenanceSystem: this.propertyMaintenanceSystem.resource.uri }
  }

  newSystemMediaResource(id : any) {
    return new MaintenanceLogResource(id)
  }


}
