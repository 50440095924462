
import { MaintenanceItemTypeSchemaResource } from "@/resources";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({components:{}})
export default class SchemaUpdateComponent extends Vue {
  
  @Prop({ required : true }) readonly latestSchema!: MaintenanceItemTypeSchemaResource
  @Prop() readonly currentSchema!: MaintenanceItemTypeSchemaResource
  @Prop() readonly selectedSchema !: MaintenanceItemTypeSchemaResource

    
}
