
import { EntityResource } from "@/resources"
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import BaseDeleteAssociationResourceItemDialog from "@/components/base/BaseDeleteAssociationResourceItemDialog.vue"
import JobSummaryBar from '@/components/jobs/JobSummaryBar.vue'
import { BaseEntityResourceComponent } from "./BaseMixins";
import { shallowRef } from "vue";
const { DateTime } = require("luxon")

@Component({components:{BaseOptionsMenu, BaseDeleteAssociationResourceItemDialog, JobSummaryBar}})
export default class BaseEventTimelineNavigatorDialog extends Mixins(BaseEntityResourceComponent) {
    @Prop({ required : true, default: false}) readonly value!: boolean
    @Prop() readonly startingResource!: EntityResource
    @Prop({ default : () => DateTime.DATE_FULL }) readonly dateFormat! : Object
    @Prop({default: false}) readonly hideRelativeDate !: boolean
    @Prop({required: true}) readonly entityLabel !: string
    @Prop({ default: false }) readonly hideOptions!: boolean

    $refs!: {
      calendar: any
    }

    selectedIdx : number = 0
    startingResourceChanged : boolean = false

    @Watch("startingResource", {immediate: true})
    updateStartingResource() {
        this.startingResourceChanged = true
    }

    makeValueRef(o : any) {
      return shallowRef(o);
    }

    arrowKeyListener(e: KeyboardEvent) {
      if (e.code === 'ArrowLeft' && this.selectedIdx!=0) {
          this.selectedIdx--
          e.preventDefault()
      } else if (e.code === 'ArrowRight' && this.selectedIdx!=this.size-1) {
          this.selectedIdx++
          e.preventDefault()
      }
    }

    mounted() {
      window.removeEventListener('keyup', this.arrowKeyListener);
      window.addEventListener('keyup', this.arrowKeyListener);
    }
    beforeUnmount() { // vue3
      window.removeEventListener('keyup', this.arrowKeyListener)
    }
    beforeDestroy() {  // vue2
     window.removeEventListener('keyup', this.arrowKeyListener)
    }

    

    @Watch("rdata")
    eventsUpdated() {

      // close dialog if no events
      if (this.size == 0) {
        this.selectedIdx = 0
        this.$emit("input", false)
      }
      // use starting resource if just opened
      else if (this.startingResource && this.startingResourceChanged) {
        this.selectedIdx = this.rdata.findIndex((ne : any) => ne.id === this.startingResource.data().id)
        this.selectedIdx = this.selectedIdx === -1 ? 0 : this.selectedIdx
        this.startingResourceChanged = false
      }
      // if current index is out of bounds, adjust
      else if (this.selectedIdx == this.size) {
        this.selectedIdx--
      }
    }

    dayClick(d : any) {
      if (d.attributes.length > 0) {
        this.selectedIdx = d.attributes[0].key
      }
    }

    get selectedEvent() {
      // selectedIdx may be out of range after a deletion, checked
      // by watch
      if (!this.size || this.selectedIdx >= this.size) return null
      return this.rdata[this.selectedIdx]
    }

    get selectedResource() {
      // selectedIdx may be out of range after a deletion, checked
      // by watch
      if (!this.size || this.selectedIdx >= this.size) return null
      return this.resources[this.selectedIdx]
    }

    moveCalendar() {
      // move calendar to this date
     const dateStr = this.selectedEvent[this.sortFieldName]
     this.$refs.calendar.focusDate(DateTime.fromISO(dateStr).toJSDate())
    }

    /**
     * Max date to show in the calendar (first entry as we are sorted descending)
     */
    get calendarMaxDate() {
      if (!this.size || this.selectedIdx >= this.size) return undefined
      const dateStr = this.rdata[0][this.sortFieldName]
      return DateTime.fromISO(dateStr).toJSDate()
    }

    get sortFieldName() : string {
      return this.selectedResource ? this.selectedResource.sortField : "createdDate"
    }

    get calendarAttributes() {
      if (!this.size || this.selectedIdx >= this.size) return []
      return this.rdata.map((e : any, idx : number) => ({
        key: idx,
        highlight: true,
        dates : DateTime.fromISO(e[this.sortFieldName]).toJSDate()
      }))
    }
}
