
import {Component, Prop} from 'vue-property-decorator'
import {EntityResource} from "@/resources"
import { BaseMaintenanceJobLogComponent } from '../base/BaseMixins'
import MediaImageStrip from "@/components/media/MediaImageStrip.vue"
const { DateTime } = require("luxon")

@Component({components: {MediaImageStrip}})
export default class JobLogsList extends BaseMaintenanceJobLogComponent {

  @Prop({}) readonly avatarSize !: string | undefined
  @Prop({}) readonly contentClass !: string | undefined

  navigatorDialog : boolean = false
  editDialog : boolean = false
  selectedResource : EntityResource | null = null

  get contentClasses() {
    return 'text-wrap body-2 ' + (this.contentClass ? " " + this.contentClass : '')
  }

  // TODO filter and all other occurences
  formatDate(d : any) {
    return DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_MED)
  }
  // TODO filter
  dateRelative(theDate : string) {
    return DateTime.fromISO(theDate).toRelative()
  }

  get title() {
    return "Maintenance logs" + (this.size ? " (" + this.size + ")" : "")
  }

}
