
import {Vue, Component, Prop} from 'vue-property-decorator'
import SchedulingCalendar from '@/components/scheduling/SchedulingCalendar.vue'
import SchedulingPool from '@/components/scheduling/SchedulingPool.vue'
import PropertySearchField from '@/components/properties/PropertySearchField.vue'
import PropertyClientAddBothDialog from "@/components/properties/PropertyClientAddBothDialog.vue"
import PropertyAddUnscheduledJobDialog from '@/components/properties/PropertyAddUnscheduledJobDialog.vue'
import ClientAddDialog from "@/components/clients/ClientAddDialog.vue"
@Component({name:"SchedulingView", metaInfo: {title:process.env.VUE_APP_NAME + " - Scheduling dashboard"}, components : {PropertyAddUnscheduledJobDialog, PropertyClientAddBothDialog, ClientAddDialog, PropertySearchField, SchedulingCalendar, SchedulingPool}})
export default class SchedulingView extends Vue {
  @Prop({}) eventDate !: any
  jobCount : number = 0
  jobCountColor : string = 'gray'
  fab : boolean = false
  hidePool : boolean = false
  propertyClientAddDialog : boolean = false
  cAddDialog : boolean = false
  appointmentAddDialog : boolean = false

}
