
import { MaintenanceJobResource } from '@/resources'
import {Component} from 'vue-property-decorator'
import { BaseMaintenanceJobComponent } from '../base/BaseMixins'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import JobStatusChip from './JobStatusChip.vue'

@Component({components: {BaseOptionsMenu, JobStatusChip}})
export default class JobStatusChipSelector extends BaseMaintenanceJobComponent {
  
  active : boolean = false
  
  hasAppointments : boolean = false

  postEntityUpdate() {
    this.resource.appointments.getAssociation({projection: 'appointmentDetail'}).then((r) => {
      if (Array.isArray(r) && r.length > 0) {
        this.hasAppointments = true
      }
    })
  }

  get validStatuses() {
    // if sched/unsched we can got to closed
    if (this.rdata.status == MaintenanceJobResource.STATUS_OPEN_SCHEDULED
    ||  this.rdata.status == MaintenanceJobResource.STATUS_OPEN_UNSCHEDULED) {
      return [
        {status : MaintenanceJobResource.STATUS_CLOSED_COMPLETED},
        {status : MaintenanceJobResource.STATUS_CLOSED_CANCELLED},
      ]
    }

    // if closed, allow move to sched if appts, or unsched if none
    if (MaintenanceJobResource.STATUS_CLOSED.includes(this.rdata.status)) {
      return this.hasAppointments 
        ? [{status : MaintenanceJobResource.STATUS_OPEN_SCHEDULED}]
        : [{status : MaintenanceJobResource.STATUS_OPEN_UNSCHEDULED}]
    }
    // else if close we can go only to sched if there 
    return []
  }

  selectStatus(status : any) {
    if (this.rdata.status != status) {
      this.$emit('change', status)
    }
  }

}
