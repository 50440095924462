
import { MaintenanceJobResource, MaintenanceLogResource, PropertyMaintenanceSystemsResource, PropertyResource } from "@/resources";
import AsyncComputed from "vue-async-computed-decorator";
import { Component, Prop, ProvideReactive } from "vue-property-decorator"
import BaseHtmlEditor from "../base/BaseHtmlEditor.vue";
import { BaseMaintenanceJobComponent, RequiredProjections } from "../base/BaseMixins";
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue";
import BasePanel from "../base/BasePanel.vue";
import BaseResourceProperty from "../base/BaseResourceProperty.vue";
import ClientName from "../clients/ClientName.vue";
import ContactsList from "../contacts/ContactsList.vue";
import InstantUpdateEnumSelector from "../forms/InstantUpdateEnumSelector.vue";
import InstantUpdateHtmlTextArea from "../forms/InstantUpdateHtmlTextArea.vue";
import JobCloseCancelDialog from "../jobs/JobCloseCancelDialog.vue";
import JobFormDialog from "../jobs/JobFormDialog.vue";
import JobLogNavigatorDialog from "../jobs/JobLogNavigatorDialog.vue";
import JobLogsList from "../jobs/JobLogsList.vue";
import JobStatusChip from "../jobs/JobStatusChip.vue";
import JobStatusChipSelector from "../jobs/JobStatusChipSelector.vue";
import PropertyAddress from "../properties/PropertyAddress.vue";
import BaseSchedulingEntry from "./BaseSchedulingEntry.vue";
import PropertyMaintenanceItemsPanel from "../properties/PropertyMaintenanceItemsPanel.vue";
import MaintenanceItemPrototypeSearch from "../maintenanceSystems/MaintenanceItemPrototypeSearch.vue"

@RequiredProjections("*")
@Component({components : {MaintenanceItemPrototypeSearch, PropertyMaintenanceItemsPanel, JobLogNavigatorDialog, JobCloseCancelDialog, JobFormDialog, JobStatusChipSelector, InstantUpdateEnumSelector, ContactsList, ClientName, PropertyAddress, JobStatusChip, JobLogsList, BaseSchedulingEntry, BasePanel, BaseOptionsMenu, BaseHtmlEditor, BaseResourceProperty, InstantUpdateHtmlTextArea}})
export default class AppointmentJobDetailsPanel extends BaseMaintenanceJobComponent {

  @Prop({default: false}) showPropertyClient !: boolean 
  @Prop({default: false}) allowBump !: boolean
  @Prop({default: false}) allowCopy !: boolean
  @Prop({default: 500}) textHeight !: number
  @Prop({default: "600px"}) width !: string | number

  quickEditItems : boolean = false
  updateListIdx : number = 0
  tab : any = ""
  jobIdx : any = 0
  pmsResource !: PropertyMaintenanceSystemsResource
  @ProvideReactive() propertyResource !: PropertyResource
  selectedLog : MaintenanceLogResource | null = null
  navigatorDialog : boolean = false
  addJobDialog : boolean = false
  copyJobDialog : boolean = false
  closeCancelDialog : boolean = false
  closeCancelDialogCancelling : boolean = false

  editJobDialog : boolean = false
  jobToEdit : MaintenanceJobResource | null = null
  jobToEditProperty : PropertyResource | null = null

  async editJob() {
    let job = this.jobData[this.jobIdx]
    let pid = job.property_id ? job.property_id : job.property.id

    this.jobToEdit = new MaintenanceJobResource(job.id)
    this.jobToEditProperty = new PropertyResource(pid)
    await this.jobToEdit.get()
    await this.jobToEditProperty.get()
    
    this.editJobDialog = true
  }

  async copyJob() {
    let job = this.jobData[this.jobIdx]
    let pid = job.property_id ? job.property_id : job.property.id

    this.jobToEdit = new MaintenanceJobResource(job.id)
    this.jobToEditProperty = new PropertyResource(pid)
    await this.jobToEdit.get()
    await this.jobToEditProperty.get()
    
    this.copyJobDialog = true
  }
 
  // TODO same as AppointmentJobDetailsPanel.vue
  changeJobStatus(jobResource : MaintenanceJobResource, status : any) {
    // if now closed, show that dialog
    if (MaintenanceJobResource.STATUS_CLOSED.includes(status)) {
      this.closeCancelDialogCancelling = (status == MaintenanceJobResource.STATUS_CLOSED_CANCELLED)
      this.closeCancelDialog = true
    }
    else {
      // TODO errors
      jobResource.mergePatch({status : status})     
    }
  }

  closeJob(scheduleNext : boolean) {
    this.closeCancelDialog = false
    if (scheduleNext) {
      this.addJobDialog = true
    }
  }

  canEdit(job : any) {
    return !MaintenanceJobResource.STATUS_CLOSED.includes(job.status)
  }

  gotoNewJob(j : any) {
    this.$router.push({ name: 'job', params: {jid:j.data.id, pid : j.data.property_id, pmsId: j.data.propertyMaintenanceSystem_id}})
  }

  gotoJob() {
    // TODO simplify.. just url with job id ?
    const pid = this.propertyResource.data().id
    const pmsId = this.pmsResource.data().id
    const jid = this.jobData[this.jobIdx].id
    this.$router.push({ name: 'job', params: {jid:jid, pid : pid, pmsId: pmsId}})
  }
  gotoProperty() {
    // TODO simplify.. just url with job id ?
    const pid = this.propertyResource.data().id
    this.$router.push({ name: 'property', params: {pid : pid}})
  }
  
  prevJob() {
    if (this.jobIdx == 0) {
      this.jobIdx = this.size-1
    } 
    else {
      this.jobIdx--
    }
  }

  nextJob() {
    if (this.jobIdx == this.size-1) {
      this.jobIdx = 0
    } 
    else {
      this.jobIdx++
    }
  }

  get jobData() {
    return Array.isArray(this.rdata) ? this.rdata : [this.rdata]
  }

  get jobResources() {
    return Array.isArray(this.rdata) ? this.resources : [this.resource]
  }

  get contacts() {
    const contacts = []
    if (this.jobData[0].property.client && this.jobData[0].property.client.contacts) {
      contacts.push(...this.jobData[0].property.client.contacts)
    } else if (this.jobData[0].property.contacts) {
      contacts.push(...this.jobData[0].property.contacts)
    }
    return contacts
  }

  get emailSubject() {
    return "Appointment for " + this.jobData[0].name
  }

  selectLog(log : MaintenanceLogResource) {
    this.selectedLog = log
    this.navigatorDialog = true
  }

  @AsyncComputed()
  async pms() {
    if (!this.loaded) return
    this.pmsResource = await this.jobResources[this.jobIdx].propertyMaintenanceSystem.getAssociation({projection: "propertyMaintenanceSystemDetail"})
    this.propertyResource = await this.jobResources[this.jobIdx].property.getAssociation()
    // ensure we don't have a job/x/property link
    this.propertyResource = new PropertyResource(this.propertyResource.data().id)
    await this.propertyResource.get()
    
    return this.pmsResource.data()
  }

}
