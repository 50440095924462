
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import {SettingsResource, MaintenanceItemPrototypeResource, PropertyMaintenanceItemResource, MaintenanceItemTypeResource, MaintenanceItemTypeSchemaResource, PropertyMaintenanceSystemsResource} from "@/resources"
import JsonSchemaControl from "@/components/maintenanceSystems/JsonSchemaControl.vue"
import SchemaUpdateComponent from '@/components/maintenanceSystems/SchemaUpdateComponent.vue'

export const MODE_ADD_PROTOTYPE_AND_PROPERTY_ITEM = "1"
export const MODE_ADD_EDIT_PROPERTY_ITEM = "2"
export const MODE_ADD_EDIT_PROTOTYPE_ITEM = "3"

@Component({components: {BaseDialog, JsonSchemaControl, SchemaUpdateComponent}})
export default class MaintenanceItemFormDialog extends Vue { 

  static LABEL_ADD = "Add"
  static LABEL_EDIT = "Edit"

  @Prop({required: true}) mode !: "1" | "2" | "3"
  @Prop({required: true}) maintenanceItemTypeResource !: MaintenanceItemTypeResource
  @Prop({required: true}) latestSchemaResource !: MaintenanceItemTypeSchemaResource
  @Prop({required: true}) currentSchemaResource !: MaintenanceItemTypeSchemaResource
  @Prop({required: true, default: false}) value !: boolean 
  @Prop() maintenanceItemPrototypeResource !: MaintenanceItemPrototypeResource
  @Prop() propertyMaintenanceItemResource !: PropertyMaintenanceItemResource
  @Prop() propertyMaintenanceSystemResource !: PropertyMaintenanceSystemsResource
  
  @Prop() maintenanceSystemUris !: string[]

  saveError : any | null = null
  saving : boolean = false
  valid : any = null
  data : any = {}
  ready : boolean = false
  updatedSchema : MaintenanceItemTypeSchemaResource | null = null
  

  get prototypeOnly() {
    return (this.mode == MODE_ADD_EDIT_PROTOTYPE_ITEM)
  }

  updateFormModel(updatedData : any) {
    this.data.formModel = updatedData
    //@ts-ignore
    this.valid = this.$refs.form.validate()
  }

  @Watch("value", {immediate: true})
  valueChanged(newVal: boolean, oldVal : boolean) {
    
    this.ready = false

    // setup when showing dialog
    if (newVal && newVal != oldVal) {

      // reset any updated schema
      this.updatedSchema = null

      //
      // prototype exists, adding/editing a property maintenance item
      //
      if (this.mode == MODE_ADD_EDIT_PROPERTY_ITEM) {

        if (!this.maintenanceItemPrototypeResource) { throw new Error("maintenanceItemPrototypeResource must be set if adding/editing a property maintenance item.")}
        
        // set form schema to properties
        Vue.set(this.data, 'formSchema', MaintenanceItemTypeSchemaResource.filterSchemaProperties(this.currentSchemaResource.data().jsonSchema, false));

        // editing property
        if (this.propertyMaintenanceItemResource) {
          
          // data
          Vue.set(this.data, 'propertyData', {...this.propertyMaintenanceItemResource.data()});
          Vue.set(this.data, 'formModel', {...this.propertyMaintenanceItemResource.data().model});
        } 
        // new property
        else {
          if (!this.propertyMaintenanceSystemResource) { throw new Error("propertyMaintenanceSystemResource must be set if adding a property maintenance item.")}
        
          // data
          Vue.set(this.data, 'propertyData', {...SettingsResource.defaultObject("propertyMaintenanceItem")});
          Vue.set(this.data, 'formModel', {});
          
          // relationships
          Vue.set(this.data.propertyData, 'prototype', this.maintenanceItemPrototypeResource.uriFull);
          Vue.set(this.data.propertyData, 'propertyMaintenanceSystem', this.propertyMaintenanceSystemResource.uriFull);
        }
      }

      //
      // adding/editing a prototype only
      //
      else if (this.mode == MODE_ADD_EDIT_PROTOTYPE_ITEM) {

        if (!this.currentSchemaResource) {throw new Error("currentSchema must be set if editing a prototype.")}

        // set form schema to prototype
        Vue.set(this.data, 'formSchema', MaintenanceItemTypeSchemaResource.filterSchemaProperties(this.currentSchemaResource.data().jsonSchema, true));

        // editing
        if (this.maintenanceItemPrototypeResource) {

          Vue.set(this.data, 'formModel', {...this.maintenanceItemPrototypeResource.data().prototypeModel});
          Vue.set(this.data, 'prototypeData', {...this.maintenanceItemPrototypeResource.data(), name : null});

          // TODO allow editing systems
          delete this.data.prototypeData.maintenanceSystems

          // TODO deal with schema updates (current isn't same as latest)
          // compare current and latest schemas
        } 
        // new
        else {

          if (!this.maintenanceSystemUris) {throw new Error("maintenanceSystemUris must be set if adding a prototype+property.")}
          
          // data
          Vue.set(this.data, 'prototypeData', {...SettingsResource.defaultObject("maintenanceItemPrototype")});
          Vue.set(this.data, 'formModel', {});

          // relationships
          Vue.set(this.data.prototypeData, 'maintenanceItemType', this.maintenanceItemTypeResource.uriFull);
          Vue.set(this.data.prototypeData, 'maintenanceSystems', this.maintenanceSystemUris);
          Vue.set(this.data.prototypeData, 'maintenanceItemTypeSchema', this.latestSchemaResource.uriFull);
        
        }
      }

      //
      // Adding both new prototype and property
      //
      else if (this.mode == MODE_ADD_PROTOTYPE_AND_PROPERTY_ITEM) {

        if (!this.propertyMaintenanceSystemResource) {throw new Error("propertyMaintenanceSystem must be set if adding a prototype+property.")}

        // set form schema to full
        Vue.set(this.data, 'formSchema', this.currentSchemaResource.data().jsonSchema);

        // data
        Vue.set(this.data, 'prototypeData', {...SettingsResource.defaultObject("maintenanceItemPrototype")});
        Vue.set(this.data, 'propertyData', {...SettingsResource.defaultObject("propertyMaintenanceItem")});
        Vue.set(this.data, 'formModel', {});

        // relationships
        Vue.set(this.data.prototypeData, 'maintenanceItemType', this.maintenanceItemTypeResource.uriFull);
        Vue.set(this.data.prototypeData, 'maintenanceSystems', this.maintenanceSystemUris);
        Vue.set(this.data.prototypeData, 'maintenanceItemTypeSchema', this.latestSchemaResource.uriFull);
        Vue.set(this.data.propertyData, 'propertyMaintenanceSystem', this.propertyMaintenanceSystemResource.uriFull);

      }

      this.ready = true
    }
  }

  updateSchema(latestSchema : MaintenanceItemTypeSchemaResource) {
    // we would only be updating if editing a prototype or property
    if (this.mode == MODE_ADD_EDIT_PROPERTY_ITEM && this.propertyMaintenanceItemResource
    ||  this.mode == MODE_ADD_EDIT_PROTOTYPE_ITEM && this.maintenanceItemPrototypeResource) {

      this.saving = true
      this.maintenanceItemPrototypeResource.mergePatch({maintenanceItemTypeSchema : latestSchema.uriFull}).then(() => {
        
        this.saving = false
        this.updatedSchema = latestSchema

        // update schema to latest
        if (this.mode == MODE_ADD_EDIT_PROTOTYPE_ITEM) {
          Vue.set(this.data, 'formSchema', MaintenanceItemTypeSchemaResource.filterSchemaProperties(latestSchema.data().jsonSchema, true));
        }
        else if (this.mode == MODE_ADD_EDIT_PROPERTY_ITEM) {
          Vue.set(this.data, 'formSchema', MaintenanceItemTypeSchemaResource.filterSchemaProperties(latestSchema.data().jsonSchema, false));
        }

      })
      .catch((err) => {
        console.error(err)
        this.saveError = err
        this.saving = false
      })
    }
    
  }

  dialogTitle() {
    // TODO get from API... need profile
    const bt = this.buttonTitle()
    const title = (bt == MaintenanceItemFormDialog.LABEL_ADD ? "New" : bt)

    if (this.mode == MODE_ADD_EDIT_PROPERTY_ITEM) {
      return title + " maintenance item"
    }
    else if (this.mode == MODE_ADD_EDIT_PROTOTYPE_ITEM
         || this.mode == MODE_ADD_PROTOTYPE_AND_PROPERTY_ITEM) {
      return title + " " + (this.maintenanceItemTypeResource ? this.maintenanceItemTypeResource.data().name : "equipment/part")
    }
    
    return title
  }

  buttonTitle() {
    if (this.mode == MODE_ADD_EDIT_PROPERTY_ITEM) {
      return this.propertyMaintenanceItemResource ? MaintenanceItemFormDialog.LABEL_EDIT : MaintenanceItemFormDialog.LABEL_ADD
    }
    else if (this.mode == MODE_ADD_EDIT_PROTOTYPE_ITEM) {
      return this.maintenanceItemPrototypeResource ? MaintenanceItemFormDialog.LABEL_EDIT : MaintenanceItemFormDialog.LABEL_ADD
    }
    return MaintenanceItemFormDialog.LABEL_ADD
  }

  closeDialog() {
    // reset form validation
    const theForm : any = this.$refs.form
    theForm.resetValidation()
    
    this.$emit('input', false)
  }

  save() {
    
    var savePromise : Promise<any> = Promise.resolve();
    var modelToSave

    if (this.mode == MODE_ADD_EDIT_PROPERTY_ITEM) {
      modelToSave = {id : this.data.propertyData.id, model : this.data.formModel}

      savePromise = this.propertyMaintenanceItemResource 
        ? this.propertyMaintenanceItemResource.fullResource().mergePatch(modelToSave)
        : new PropertyMaintenanceItemResource().post(modelToSave)
    }
    else if (this.mode == MODE_ADD_EDIT_PROTOTYPE_ITEM) {
      modelToSave = {...this.data.prototypeData, prototypeModel : this.data.formModel}

      savePromise = this.maintenanceItemPrototypeResource 
        ? this.maintenanceItemPrototypeResource.fullResource().mergePatch(modelToSave)
        : new MaintenanceItemPrototypeResource().post(modelToSave)
    }
    else if (this.mode == MODE_ADD_PROTOTYPE_AND_PROPERTY_ITEM) {
      // save prototype first
      // use saved prototype ref to set property.prototype property

      // TODO currently need to define prototypes first
      throw new Error("MODE_ADD_PROTOTYPE_AND_PROPERTY_ITEM not implemented yet")
    }

    // save
    this.saving = true
    savePromise.then(() => {
        this.saving = false
        this.$emit('update', true)
        this.$emit('input', false)
      })
      .catch((err) => {
        console.error(err)
        this.saveError = err
        this.saving = false
      })

  }

}
