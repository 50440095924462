
import { ApiResource, AppointmentResource, AssociationResource, ClientResource, EntityResource, MaintenanceJobResource, PropertyResource, SettingsResource, UserResource } from "@/resources"
import { DateTime } from "luxon"
import AsyncComputed from "vue-async-computed-decorator"
import { Drop } from 'vue-drag-drop'
import { Component, Prop, Vue, Watch} from "vue-property-decorator"
import BasePanel from "../base/BasePanel.vue"
import UserSelector from "../user/UserSelector.vue"
import AppointmentsMap from "./AppointmentsMap.vue"
import SchedulingAppointment from "./SchedulingAppointment.vue"
import AppointmentDetailsPanel from "./AppointmentDetailsPanel.vue"
import AppointmentJobDetailsPanel from "./AppointmentJobDetailsPanel.vue"
import BaseSchedulingEntry from "./BaseSchedulingEntry.vue"
import BaseReportButton from "../base/BaseReportButton.vue"
import PropertyAddScheduledJobDialog from '@/components/properties/PropertyAddScheduledJobDialog.vue'

@Component({components : {PropertyAddScheduledJobDialog, BaseReportButton, BaseSchedulingEntry, SchedulingAppointment, BasePanel, Drop, AppointmentsMap, UserSelector, AppointmentDetailsPanel, AppointmentJobDetailsPanel}})
export default class SchedulingCalendar extends Vue {
  @Prop({}) eventDate !: any
  @Prop({default: false}) showUnscheduledJobCount !: boolean
  @Prop({default: "gray"}) jobCountColor !: string
  @Prop() unscheduledJobCount !: any
  
  calendarType : any = "week"
  calendarTypes : any = [
    {text:'Day', value: 'day'},
    {text:'Week', value: 'week'},
    {text:'Month', value: 'month'},
    {text:'4 day', value: '4day'}
  ]
  eventOverlapMode : "column" | "stack" = "column"
  calendarStartDate : string = (this.eventDate ? DateTime.fromISO(this.eventDate) :  DateTime.now()).startOf('week').toISODate()
  events : any = []
  intervalHeight : number = 40
  intervalMinutes : number = 30
  numIntervals : number = 25
  minAppointmentDuration : number = 15
  minAppointmentStartTime : string = "6:00"
  hoveredAppointment : any = null
  
  hoverDay : boolean = false
  fetching : boolean = false
  dragging : boolean = false
  dragEvent : any = null
  dragTime : any = null
  dragStart: any = null
  createEvent: any = null
  createStart: any = null
  extendOriginal: any = null
  newEventDates = {start : "", end : ""}
    
  calendarOwnerUri : string | null = null
  calendarOwnerEmail : string | null = null
  calendarId : string | null = null

  calendarStartDateUTC !: string
  calendarEndDateUTC !: string

  appointmentsResource !: AssociationResource<AppointmentResource[]>
  selectedEvent : any = null
  appointmentDialog = false
  showScheduledJobAddDialog = false
  isMounted : boolean = false

  prevLoading : boolean = false
  nextLoading : boolean = false

  serviceSheetParams(date : string) {
    var startDate = DateTime.fromISO(date)
    var endDate = startDate.plus({day: 1})

    // ensure dates to server are UTC
    return new URLSearchParams({startDate: startDate.setZone('utc').toISO(), endDate: endDate.setZone('utc').toISO()})
  }

  clickMore(evt : any, nativeEvent : Event) {
    nativeEvent.stopPropagation()
    this.dayView(evt.date)
  }

  completeNewAppointment() {
    this.cancelNewAppointment()
    this.showScheduledJobAddDialog = false
  }

  cancelNewAppointment() {
    var idx = this.events.findIndex((e:any) => e.data.id === -666)
    if (idx != -1) {
      this.events.splice(idx, 1)
    }
  }

  dayView(d : string) {
    this.calendarStartDate = d
    this.calendarType='day'
  }

  calPrev() {
   //this.prevLoading = true
    // @ts-ignore
    this.$refs.calendar.prev()
  }
  calNext() {
    //this.nextLoading = true
    // @ts-ignore
    this.$refs.calendar.next()
  }

  deleteAppointment() {
    this.appointmentDialog=false

    // TODO errors
    this.selectedEvent.resource.delete()
  }

  // TODO extract this entity notification into some easy to use mixin.
  // used in the BaseMixins.ts too
  entityUpdated(event : string) {
    const [type, uri] = event.split(": ")

    // if add, check to see if event date is within the current window, if so add it
    // TODO would be good to cache queries to speed up nav, need to work through
    // all the add/update/delete scenarios
    if (type === "ADD" && uri.indexOf("appointments") > 0) {
      var parts = uri.split("/")
      var id = parts[parts.length-1]

      var newAr = new AppointmentResource(id + "?projection=appointmentDetail")
      newAr.get().then(state => {
        var aStart = DateTime.fromISO(state.data.startDate)
        var cStart = DateTime.fromISO(this.calendarStartDateUTC, {zone : "utc"})
        var cEnd = DateTime.fromISO(this.calendarEndDateUTC, {zone : "utc"})
        var withinView = (cStart <= aStart && aStart <= cEnd)

        // search each job in the appointment and look for any dummy
        // placeholder events which used a job id, remove it
        var placeholderRemoved = false
        state.data.jobs.forEach((j:any) => {
          const jobId = j.id
          const idxToSwap = this.events.findIndex((e:any) => e.data.id == jobId)
          
          if (withinView && !placeholderRemoved) {
            if (idxToSwap >= 0) {
              this.$set(this.events, idxToSwap, this.appointmentToEvent(state.data, newAr))
              placeholderRemoved = true
            }
          }
        })

        // if no placeholder, add the event, it was another client that
        // added it
        if (!placeholderRemoved) {
          this.events.push(this.appointmentToEvent(state.data, newAr))
        }
        
      })
    }

    if (type === "DELETE" && uri.indexOf("appointments")) {
      const idx = this.events.findIndex((e:any) => !e.isTemporary && e.resource.resource.uri.indexOf(uri) >= 0)
      if (idx >=0){
        this.events.splice(idx, 1)
      }
    }
    else if (type === "UPDATE" && uri.indexOf("appointments")) {
      const idx = this.events.findIndex((e:any) => !e.isTemporary && e.resource.resource.uri.indexOf(uri) >= 0)
      if (idx >=0) {
        var ar = this.events[idx].resource
        // clear cache and fetch latest
        ar.get(false).then(() => {
          this.$set(this.events, idx, this.appointmentToEvent(ar.data(), ar))
        })
      }
    }
  }
  beforeMount() { 
    this.$eventBus.off('entityUpdate', this.entityUpdated)
    this.$eventBus.on('entityUpdate', this.entityUpdated)
    window.removeEventListener('keyup', this.keyListener);
    window.addEventListener('keyup', this.keyListener);
  }
  beforeUnmount() { 
    this.$eventBus.off('entityUpdate', this.entityUpdated)
    window.removeEventListener('keyup', this.keyListener)
  } // vue3
  beforeDestroy() {  
    this.$eventBus.off('entityUpdate', this.entityUpdated)
    window.removeEventListener('keyup', this.keyListener)
  } // vue2

  keyListener(e: KeyboardEvent) {
    // @ts-ignore
    if (e.target.tagName.toLowerCase() === 'body') {
      if (e.code === 'ArrowLeft') {
          this.calPrev()
          e.preventDefault()
      } else if (e.code === 'ArrowRight') {
          this.calNext()
          e.preventDefault()
      } else if (e.key === 'd') {
        this.calendarType = 'day'
        e.preventDefault()
      } else if (e.key === 'w') {
        this.calendarType = 'week'
        e.preventDefault()
      } else if (e.key === 'm') {
        this.calendarType = 'month'
        e.preventDefault()
      } else if (e.key === 'x') {
        this.calendarType = '4day'
        e.preventDefault()
      }
    }
  }

  
  clickDay(evt: any) {
    // New event: month view add placeholder
    if (this.calendarType == "month") {
      // default to noon-1
      let start = DateTime.fromISO(evt.date).set({hour: 12})
      let end = start.plus({hours: 1})
      
      // add and then show new appt/job dialog
      this.events.push(this.createNewManualEvent(start.toJSDate().getTime(), end.toJSDate().getTime()))

      // init dialog dates
      this.newEventDates.start = start.toISO()
      this.newEventDates.end = end.toISO()

      // show dialog
      this.showScheduledJobAddDialog = true
    }
  }

  showAppointment(evt : any) {
    if (!this.dragging) {
      this.selectedEvent = evt.event
      evt.nativeEvent.stopPropagation()
      this.appointmentDialog=true
    }
    this.dragging = false
  }

  @Watch("appointmentDialog") 
  appointmentDialogChanged() {
    if (!this.appointmentDialog) {
      this.selectedEvent = null
    }
  }

  get cal() {
    return this.isMounted ? this.$refs.calendar : null
  }

  get nowY() {
    // @ts-ignore
    return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
  }

  get calendarIssue() : string | null {
    if (!this.isMounted) {
      return null
    }
    if (!this.calendarOwnerEmail) {
      return "Technician must be selected before adding appointments."
    }
    if (!this.calendarId) {
      return "Technician must have a default calendar set" 
        + (this.calendarOwnerEmail ? " (" + this.calendarOwnerEmail + ")" : '') + "."
    }
    return null
  }

  get singleLine() {
    return this.calendarType == 'month'
  }

  mounted() {
    this.calendarOwnerUri = this.defaultAppointmentLead
    this.isMounted = true
  }

  get defaultAppointmentLead() {
    return SettingsResource.setting(SettingsResource.SETTING_APPOINTMENT_LEAD)
  }

  get technicianAppointments() {
    if (!this.calendarOwnerEmail) return this.events;
    
    return this.events.filter((e: any) => {
      return e.appointmentLead == this.calendarOwnerEmail
    })
  }

  @AsyncComputed()
  async changeCalendarOwner() {

    if (!this.calendarOwnerUri) {
      this.calendarId = null
      this.calendarOwnerEmail = null
      return
    }

    const userUri = this.calendarOwnerUri
    
    const userResource = new UserResource(userUri)
    const userState = await userResource.get(false)
    this.calendarOwnerEmail = userState.data.email
    const calendarUrl = userState.data.settings.appointmentCalendarUrl
    
    // TODO clean this up... calendar URI are only under user... make sense ?
    const calendars = await userResource.calendars.getAssociation()
    const appointmentCalendar = calendars.find((c:any) => c.uriFull == calendarUrl) 
    this.calendarId = null
    if (appointmentCalendar) {
      this.calendarId = appointmentCalendar.data().id
    }
  }

  handleDropDragover(data : any, evt : any) {
    // block drop if no user or calendar id set for appointments
    if (!this.calendarOwnerEmail || !this.calendarId) {
      evt.dataTransfer.dropEffect = 'none';
    }
  }

  handleDrop(date : any, time : any, data:any, evt: any) {
    // ensure duration does not go past today
    // NOTE: we use the duration from the first job
    // TODO pick longest duration if multijob or add them up ?
    const start = new Date(date + " " + time)
    const end = DateTime.fromJSDate(start).plus({ hours: data.estimatedDuration ? data.estimatedDuration : 1}).toJSDate()

    // create appointment job refs
    const hasMultipleJobs = data.multipleJobs && data.multipleJobs.length > 1
    const apptJobs = hasMultipleJobs 
            ? data.multipleJobs.map((j:EntityResource) => j.uriFull) 
            : [new MaintenanceJobResource(data.id).uriFull]

    var newAppt = {
      startDate : start.toISOString(),
      endDate : end.toISOString(),
      appointmentLead : this.calendarOwnerEmail,
      calendarId : this.calendarId,
      jobs : apptJobs
    }

    // add placeholder event, replaced when we get the ADD event
    this.events.push({
      name: hasMultipleJobs ? "Multiple jobs" : data.name,
      data : {id: data.id},
      lineOne : PropertyResource.getPropertyAddress(data.property),
      lineTwo : data.property.client ? ClientResource.getClientName(data.property.client) : "No client file",
      start: new Date(newAppt.startDate),
      end: new Date(newAppt.endDate),
      timed: true,
      appointmentLead : newAppt.appointmentLead,
      isTemporary : true,
    })

    // TODO handle errors
    // add appointment with pointer to existing jobs
    new AppointmentResource().post(newAppt).then(() => {
    }).catch((e) => console.error(e))

    // remove highlight
    this.hoverDropLeave(null, evt)

  }

  hoverDropEnter(data : any, evt : any) {
    if (evt.target) {
      evt.target.style.border = "2px dashed rgba(0,0,0,.3)";
      evt.target.style.height = ((data.estimatedDuration ? data.estimatedDuration * (60/this.intervalMinutes) : 1) * this.intervalHeight) + "px"
    }
  }
  hoverDropLeave(data : any, evt : any) {
    if (evt.target) {
      evt.target.style.border = "none"
      evt.target.style.height = "100%"
    }
  }

  appointmentToEvent(appt : any, apptResource : AppointmentResource) {
    
    return {
        name: appt.jobs.length > 1 ? "Multiple jobs" : appt.jobs[0].name,
        lineOne : PropertyResource.getPropertyAddress(appt.jobs[0].property),
        lineTwo : appt.jobs[0].property.client ? ClientResource.getClientName(appt.jobs[0].property.client) : "No client file",
        start: new Date(appt.startDate),
        end: new Date(appt.endDate),
        status : appt.status,
        timed: true,
        data : appt,
        appointmentLead : appt.appointmentLead,
        resource : apptResource,
      }
  }

  calendarChange(calDates : any) {
    // bump end date to get all events on that day
    var startDate = calDates.start.date
    var endDate = DateTime.fromISO(calDates.end.date).plus({day: 1}).toISODate()

    // ensure dates are in GMT format, from ISO uses browser time which is ok.
    this.calendarStartDateUTC = DateTime.fromISO(startDate).setZone("utc").toISODate()
    this.calendarEndDateUTC = DateTime.fromISO(endDate).setZone("utc").toISODate()

    this.fetchEvents();
  }

  fetchEvents() {
    if (this.fetching) return
    
    this.fetching = true
    this.events.splice(0)
    this.appointmentsResource = new ApiResource().appointments

    // the search query requires full ISO datetime format
    let qStart = DateTime.fromISO(this.calendarStartDateUTC).toISO()
    let qEnd = DateTime.fromISO(this.calendarEndDateUTC).toISO()

    // we always skip cache as ketting will cache by query including params, so we would have
    // to find the collection query that contained the changed entity and only clear cache on that one,
    // for now we skip cache.   TODO separate cache by URI -> collection query, if URI changed, only
    // clear cache on that query.. wouldn't catch new ones ?
    var apiAppointmentSearch : AssociationResource<AppointmentResource[]> = new AppointmentResource().searchByStartDateBetween
    var searchCall = apiAppointmentSearch.getAssociation(
      {startDate: qStart, 
       endDate:  qEnd}, false)
    
    searchCall.then((appts : any) => {
      appts.forEach((ar : AppointmentResource) => this.events.push(this.appointmentToEvent(ar.data(), ar)))
    })
    // TODO errors
    .catch((e:any) => console.error(e))
    .finally(() => {
      this.fetching = false
      this.prevLoading = false
      this.nextLoading = false
    })
  }

  startDrag ({ event, timed } : { event: any; timed: boolean }) {
    if (event && timed) {
      this.dragEvent = event
      this.dragTime = null
      this.extendOriginal = null
    }
  }
  
  startTime (tms : any) {

    const mouse = this.toTime(tms)

    if (this.dragEvent && this.dragTime === null) {
      const start = this.dragEvent.start

      this.dragTime = mouse - start
    } else {
      
      // New event: add placeholder
      this.createStart = this.roundTime(mouse)
      this.createEvent = this.createNewManualEvent(this.createStart, this.createStart) 
      this.events.push(this.createEvent)
    }
  }

  createNewManualEvent(start: any, end: any) {
    // for creating new events, add placeholder
    return {
        name: "New job",
        data : {id: -666},
        start: start,
        end: end,
        timed: true,
        appointmentLead : this.calendarOwnerEmail,
        isTemporary : true,
        isNew : true,
      }
  }
      
  extendBottom (event : any) {
    this.createEvent = event
    this.createStart = event.start
    this.extendOriginal = event.end
  }

  mouseMove (tms : any) {
    if (this.dragEvent || this.dragTime || this.createEvent || this.createStart) {
      this.dragging = true
    }
    
    const mouse = this.toTime(tms)

    if (this.dragEvent && this.dragTime !== null) {
      const start = this.dragEvent.start
      const end = this.dragEvent.end
      const duration = end - start
      const newStartTime = mouse - this.dragTime
      const newStart = this.roundTime(newStartTime)
      const newEnd = newStart + duration

      this.dragEvent.start = newStart
      this.dragEvent.end = newEnd

    } else if (this.createEvent && this.createStart !== null) {
      const mouseRounded = this.roundTime(mouse, false)
      const min = Math.min(mouseRounded, this.createStart)
      const max = Math.max(mouseRounded, this.createStart)

      this.createEvent.start = min
      this.createEvent.end = max
    }
  }

  endDrag () {
    
    // drag event is present if moving an event
    // only update if not new
    if (this.dragEvent && !this.dragEvent.isNew) {
      if (this.timesChanged(this.dragEvent)) {
        this.updateEvent(this.dragEvent)
      }
    } 
    // else we are shifting time
    // only update if not new
    else if (this.extendOriginal && !this.createEvent.isNew) {
      if (this.timesChanged(this.createEvent)) {
        this.updateEvent(this.createEvent)
      }
    }

    // for new manual events, show dialog after dragging time
    if (this.createEvent && this.createEvent.isNew) {
      this.newEventDates.start = new Date(this.createEvent.start).toISOString()
      this.newEventDates.end = new Date(this.createEvent.end).toISOString()
      
      this.showScheduledJobAddDialog = true
    }

    this.dragTime = null
    this.dragEvent = null
    this.createEvent = null
    this.createStart = null
    this.extendOriginal = null

    //this.dragging = false
  }

  timesChanged(evt : any) {
    var oldStart = new Date(evt.data.startDate).toISOString()
    var oldEnd = new Date(evt.data.endDate).toISOString()
    var newStart = new Date(evt.start).toISOString()
    var newEnd = new Date(evt.end).toISOString()
    
    return oldStart != newStart || oldEnd != newEnd
  }

  updateEvent(evt : any) {
    
    (evt.resource as EntityResource).mergePatch({
      startDate : new Date(evt.start).toISOString(),
      endDate: new Date(evt.end).toISOString(),
      appointmentLead : this.calendarOwnerEmail,
      calendarId : this.calendarId,
    }).then(() => {
      // TODO catch errors
    })
  }

  cancelDrag () {
    if (this.createEvent) {
      if (this.extendOriginal) {
        this.createEvent.end = this.extendOriginal
      } else {
        const i = this.events.indexOf(this.createEvent)
        if (i !== -1) {
          this.events.splice(i, 1)
        }
      }
    }

    this.createEvent = null
    this.createStart = null
    this.dragTime = null
    this.dragEvent = null
  }

  roundTime (time : any, down = true) {
    const roundTo = this.minAppointmentDuration // minutes
    const roundDownTime = roundTo * 60 * 1000

    return down
      ? time - time % roundDownTime
      : time + (roundDownTime - (time % roundDownTime))
  }

  toTime (tms : any) {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
  }

  getEventColor() {
    // we let the base event component color itself.
    return "rgba(0,0,0,0)"
  }
}
